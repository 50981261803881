import "../../css/report/chart_subpage.scss";

import React from "react";
import { Empty, Layout, Select, Tabs, Typography } from "antd";
import * as _ from "lodash";
import MyComponent from "../../components/my_component";
import MyChart from "../../components/charts/my_chart";
import ChartColumnConfig from "../../components/charts/chart_column_config";
import ChartGeneralConfig from "../../components/charts/chart_general_config";
import { ChartConfig } from "../../components/models/chart_config";
import { ColumnConfig } from "../../components/models/column_config";
import { ColumnConfigTyped } from "../../components/models/column_config_typed";
import { DataColumn } from "../../components/models/data_column";
import { QueryResult } from "../../components/models/query_result";
import { ChartType } from "../../components/models/chart_type";

interface ChartSubpageProps {
    onChange: (data: ChartConfig) => void;
    chartConfig: ChartConfig;
    data: QueryResult;
    datasetColumns: { [name: string]: DataColumn };
    rightMenuCollapsed: boolean;
}

interface ChartSubpageState {}

export default class ChartSubpage extends MyComponent<ChartSubpageProps, ChartSubpageState> {
    public static defaultProps: Partial<ChartSubpageProps> = {};

    modes: { [name: string]: ChartType } = {
        "Plot chart": "plot",
        "Pie chart": "pie",
        "Figure chart": "figure",
    };

    // https://bizcharts.net/product/bizcharts/category/7/page/27
    plotChartModes: { [key: string]: string } = {
        Line: "line",
        "--Smooth line": "line-smooth",
        "--Dashed line": "line-dash",
        "--Dotted line": "line-dot",

        Area: "area",
        "--Smooth area": "area-smooth",

        Bar: "interval",
        "--Hollow bar": "interval-hollowRect",
        "--Stacked bar": "intervalStack",

        Point: "point",
        "--Square point": "point-square",
        "--Bowtie point": "point-bowtie",
        "--Diamond point": "point-diamond",
        "--Cross point": "point-cross",
    };

    pieChartModes: { [key: string]: string } = {
        Pie: "intervalStack",
        Donut: "intervalStack-donut",
    };

    chart?: MyChart;

    setChartConfig = (name: string, value: string) => {
        const config = this.props.chartConfig;
        _.set(config, name, value);

        this.props.onChange(config);
    };

    getChartConfig = (path: string, defaultValue: any = undefined): any => {
        return _.get(this.props.chartConfig, path, defaultValue);
    };

    tableData = this.props.data.rows.map((r) => {
        return _.fromPairs(
            this.props.data.columns.map((columnName, cIndex) => [columnName, r[cIndex]]),
        );
    });

    render = () => {
        return (
            <>
                <Layout.Content>{this.renderCenter()}</Layout.Content>
                <Layout.Sider
                    theme="light"
                    collapsible
                    collapsed={this.props.rightMenuCollapsed}
                    className="right-menu chart-right-menu"
                    width={460}
                    collapsedWidth={0}
                    trigger={null}
                >
                    <div className="right-menu-inner">{this.renderRightMenu()}</div>
                </Layout.Sider>
            </>
        );
    };

    renderRightMenu() {
        return (
            <Tabs onChange={() => {}} type="card">
                <Tabs.TabPane tab="Columns" key="1">
                    <ChartColumnConfig
                        datasetColumns={Object.values(this.props.datasetColumns)}
                        setChartConfig={this.setChartConfig}
                        getChartConfig={this.getChartConfig}
                        yModes={this.getYModes()}
                        colorSet={this.getCurrentColorset()}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Settings" key="2">
                    <ChartGeneralConfig
                        setChartConfig={this.setChartConfig}
                        chartConfig={this.props.chartConfig}
                    />
                </Tabs.TabPane>
            </Tabs>
        );
    }

    getYModes = () => (this.getChartType() === "plot" ? this.plotChartModes : this.pieChartModes);

    getChartType = () => this.props.chartConfig?.type ?? "plot";

    renderCenter() {
        return (
            <div className="chart-subpage">
                <div className="title-container">
                    <div className="title">
                        <Typography.Paragraph
                            editable={{
                                onChange: (name) => {
                                    this.onRename(name);
                                },
                            }}
                        >
                            {this.props.chartConfig?.name || "Untitled chart"}
                        </Typography.Paragraph>
                    </div>
                </div>

                <div className="top-toolbar">
                    <Select
                        size="small"
                        bordered={false}
                        value={this.getChartType()}
                        onChange={(value) => this.setChartConfig("mode", value)}
                    >
                        {Object.entries(this.modes).map(([name, value]) => {
                            return (
                                <Select.Option key={value} value={value}>
                                    {name}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>

                <div className="subpage-content">{this.renderChart()}</div>
            </div>
        );

        // return <div id={"chart-container-" + this.props.chartIndex}/>
    }

    getCurrentColorset = () => {
        return this.getChartConfig("colorSet", [
            "#1890FF",
            "#2FC25B",
            "#FACC14",
            "#223273",
            "#8543E0",
            "#13C2C2",
            "#3436C7",
            "#F04864",
        ]);
    };

    getError = () => {
        if (_.values(this.getChartConfig("xAxis", {})).length === 0)
            return "Need a single column for X axis";

        if (_.values(this.getChartConfig("yAxis", {})).length === 0)
            return "Need one or more columns for Y axis";

        if (
            _.values(this.getChartConfig("colors", {})).length !== 0 &&
            _.values(this.getChartConfig("yAxis", {})).length > 1
        )
            return "Only one Y axis is supported in case you select colors column";

        return null;
    };

    isOk = () => {
        return this.getError() === null;
    };

    renderChart = (): React.ReactNode => {
        const isOk = this.isOk();

        if (!isOk)
            return (
                <Empty
                    className="chart-unconfigured"
                    description="Please select columns on the right to build chart"
                >
                    <div className="chart-config-error">{this.getError()}</div>
                </Empty>
            );

        const yModes = this.getYModes();

        const xAxis: ColumnConfig[] = this.props.chartConfig.xAxis || [];
        const yAxis: ColumnConfig[] = this.props.chartConfig.yAxis || [];
        const colors: ColumnConfig[] = this.props.chartConfig.colors || [];

        const xAxisName = xAxis[0].name;

        const xAxisConfig: ColumnConfigTyped = {
            name: xAxisName,
            dataType: this.props.datasetColumns[xAxisName] ?? this.err("No column for xAxis"),
        };

        const yAxisConfigs: ColumnConfigTyped[] = Object.values(yAxis).map((item) => {
            const mode: string = _.values(yModes).includes(item.mode ?? "")
                ? item.mode ?? this.err("No mode set for column")
                : _.values(yModes)[0];

            const newItem: ColumnConfigTyped = Object.assign(_.clone(item), {
                dataType: this.props.datasetColumns[item.name],
                mode: this.getPartOfString(mode, 0, mode),
                shape: this.getPartOfString(mode, 1),
                agg: item?.agg || "mean",
            });

            return newItem;
        });

        const colorsConfigs: ColumnConfigTyped[] = Object.values(colors).map((item) => {
            return {
                name: item.name,
                dataType: this.props.datasetColumns[item.name],
            };
        });

        return (
            <MyChart
                mode={this.getChartType()}
                height={400}
                data={this.tableData}
                xAxis={xAxisConfig}
                yAxis={yAxisConfigs}
                onMount={(chart) => {
                    this.chart = chart;
                }}
                colors={colorsConfigs}
                options={_.cloneDeep(this.getChartConfig("options", {}))}
            />
        );
    };

    getPartOfString = (str: string | undefined, part: number, defValue: any = undefined) => {
        let newStr = str;

        if (!newStr) newStr = defValue;

        if (!newStr) return defValue;

        if (!newStr.includes("-")) {
            if (part === 0) return newStr;

            return defValue;
        }

        return newStr.split("-")[part];
    };

    onRename = (name: string) => {
        this.setChartConfig("name", name);
    };
}
