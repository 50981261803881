import React from "react";
import { Droppable } from "react-beautiful-dnd";
import classnames from "classnames";
import { CheckCircleFilled, DeleteOutlined } from "@ant-design/icons";
import * as _ from "lodash";
import MenuBlock from "./menu_block";
import { ColumnConfig } from "./models/column_config";

import MyComponent from "./my_component";

interface ColumnDropZoneProps {
    id: string;
    title: string;

    className?: string;

    enabled: boolean;

    maxItems: number;
    minItems: number;
    items: { [key: string]: ColumnConfig };
    itemRender?: (
        item: any,
        index: number,
        expanded: boolean,
        expand: () => void,
        change: (name: string, value: any) => void,
    ) => React.ReactNode;
    onItemsChange: (items: { [key: string]: any }) => void;
    onMounted?: (item: ColumnDropZone) => void;
}

export default class ColumnDropZone extends MyComponent<ColumnDropZoneProps, {}> {
    public static defaultProps: Partial<ColumnDropZoneProps> = {
        enabled: true,
        maxItems: 1,
        minItems: 1,
    };

    state = {
        expanded: {},
    };

    componentDidMount() {
        if (this.props.onMounted) this.props.onMounted(this);
    }

    checkKey = (key: string): boolean => {
        return !_.has(this.props.items, key);
    };

    getUniqueKey = (name: string): string => {
        if (this.checkKey(name)) return name;

        let index = 2;
        while (!this.checkKey(`${name}_${index}`)) index++;

        return `${name}_${index}`;
    };

    itemDropped = (name: string) => {
        if (_.values(this.props.items).length >= this.props.maxItems) {
            this.alertError(
                `Maximum number of columns for the field ${this.props.id} is ${this.props.maxItems}`,
            );
            return;
        }

        const newItem = {
            name,
            key: this.getUniqueKey(name),
            agg: "mean",
        };

        const { items } = this.props;
        items[newItem.key] = newItem;

        this.props.onItemsChange(items);
    };

    removeColumn = (key: string) => {
        const { items } = this.props;
        _.unset(items, key);
        this.props.onItemsChange(items);
    };

    isOk = () => {
        return (
            _.values(this.props.items).length <= this.props.maxItems &&
            _.values(this.props.items).length >= this.props.minItems
        );
    };

    itemRender = (
        item: any,
        index: number,
        expanded: boolean,
        expand: () => void,
        change: (name: string, value: any) => void,
    ): React.ReactNode => {
        if (this.props.itemRender)
            return this.props.itemRender(item, index, expanded, expand, change);

        return <></>;
    };

    render = () => {
        const ok = this.isOk();
        const items: any[] = _.values(this.props.items).slice(0, this.props.maxItems);

        return (
            <MenuBlock
                className={classnames({
                    [this.props.className || "x"]: !!this.props.className,
                    "config-drag": true,
                    "config-drag-empty": items.length === 0,
                    "config-drag-full": items.length >= this.props.maxItems,
                    "config-drag-ok": ok,
                    "config-drag-non-ok": !ok,
                    "config-drag-less-than-required": items.length < this.props.minItems,
                })}
                title={this.props.title}
                headerRight={
                    ok && (
                        <div className="config-subheader-icon-ok">
                            <CheckCircleFilled />
                        </div>
                    )
                }
            >
                {!this.props.enabled && (
                    <div className="zone-disabled">Disabled for this chart type</div>
                )}

                {this.props.enabled && (
                    <Droppable droppableId={this.props.id}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                className={classnames({
                                    "drop-zone": true,
                                    "drag-over": snapshot.isDraggingOver,
                                    "drag-fail":
                                        snapshot.isDraggingOver &&
                                        items.length >= this.props.maxItems,
                                })}
                                {...provided.droppableProps}
                            >
                                {items.map((item, index) => {
                                    return (
                                        <div className="drop-item" key={item.key}>
                                            <div className="drop-item-line">
                                                <div className="drop-item-name">{item.name}</div>
                                                <div
                                                    className="drop-item-remove"
                                                    onClick={() => {
                                                        this.removeColumn(item.key);
                                                    }}
                                                >
                                                    <DeleteOutlined />
                                                </div>
                                            </div>
                                            {this.itemRender(
                                                item,
                                                index,
                                                _.get(this.state.expanded, index, false),
                                                () => {
                                                    this.setStateValue(
                                                        `expanded.${index}`,
                                                        !_.get(this.state.expanded, index, false),
                                                    );
                                                },
                                                (name: string, value: any) => {
                                                    const newItems = this.props.items;

                                                    // eslint-disable-next-line prefer-destructuring
                                                    const key = item.key;

                                                    const newItem = newItems[key];
                                                    // @ts-ignore
                                                    newItem[name] = value;
                                                    newItems[key] = newItem;

                                                    this.props.onItemsChange(newItems);
                                                },
                                            )}
                                        </div>
                                    );
                                })}

                                {items.length < this.props.maxItems && !snapshot.isDraggingOver && (
                                    <div className="drag_here">Drag column here</div>
                                )}

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                )}
            </MenuBlock>
        );
    };
}
