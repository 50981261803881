import "../css/sql_table.scss";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import React from "react";
import * as _ from "lodash";
import { AgGridReact } from "ag-grid-react";
import { FirstDataRenderedEvent, GridReadyEvent } from "ag-grid-community/dist/lib/events";
import { ColDef, ColumnApi, GridApi } from "ag-grid-community";
import MyComponent from "./my_component";
import { QueryResult } from "./models/query_result";

interface SqlTableProps {
    data: QueryResult;
}

export default class SqlTable extends MyComponent<SqlTableProps, {}> {
    gridApi?: GridApi;

    gridColumnApi?: ColumnApi;

    getColumns = (columns: string[]): ColDef[] => {
        const cols: ColDef[] = [{ headerName: "", field: "num", pinned: "left" }];

        columns.forEach((c) => {
            cols.push({
                headerName: c,
                field: c,
                resizable: true,
                sortable: true,
                // filter: 'agTextColumnFilter'
            });
        });

        return cols;
    };

    getTableData = (rows: any[][]): any[] => {
        return rows.map((r, index: number) => {
            const row = _.fromPairs(
                this.props.data.columns.map((columnName: string, cIndex: number) => [
                    columnName,
                    r[cIndex],
                ]),
            );

            row.key = `row${index}`;
            row.num = index + 1;

            return row;
        });
    };

    columns: ColDef[] = this.getColumns(this.props.data.columns);

    tableData: any[] = this.getTableData(this.props.data.rows);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onFirstDataRendered = (event: FirstDataRenderedEvent): void => {
        const gridApi = this.gridColumnApi ?? this.err("No gridColumnApi set yet");

        gridApi.autoSizeColumns(
            this.columns.map((c: ColDef) => c.field ?? this.err("No field set")),
            false,
        );
    };

    onGridReady = (event: GridReadyEvent) => {
        this.gridApi = event.api;
        this.gridColumnApi = event.columnApi;

        // const httpRequest = new XMLHttpRequest();
        // const updateData = data => {
        //   this.setState({ rowData: data });
    };

    render() {
        // let {data} = this.props;

        return (
            <div className="table-container ag-theme-balham">
                <AgGridReact
                    columnDefs={this.columns}
                    rowData={this.tableData}
                    onFirstDataRendered={this.onFirstDataRendered}
                    onGridReady={this.onGridReady}
                    suppressColumnVirtualisation={this.columns.length < 30}
                />
            </div>
        );
    }
}
