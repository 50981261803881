import MyComponent, { ComponentState } from "../my_component";
import React, { ReactNode } from "react";
import { HttpRequestResult } from "../models/http/http_request_result";
import { HttpReportsTreeResponse } from "../models/http/http_reports_tree_response";
import { TreeSelect } from "antd";

interface ChartSelectorProps {}

interface ChartSelectorState extends ComponentState {
    currentlySelectedChart?: string | undefined;
    reports?: HttpRequestResult<HttpReportsTreeResponse>;
}

export default class ChartSelector extends MyComponent<ChartSelectorProps, ChartSelectorState> {
    public readonly state: Readonly<ChartSelectorState> = {};

    loadData = () => {
        this.requestGet(`/reports/tree`, {}, "reports", false)
            .then((data) => {})
            .catch(() => {});
    };

    componentDidMount() {
        this.loadData();
    }

    getTreeItems() {
        const data = this.state.reports?.result ?? this.err("No data received");

        return data.map((report) => {
            return {
                title: report.name,
                value: report.id,
                children: report.charts.map((c) => {
                    return {
                        title: c.name,
                        value: c.key,
                    };
                }),
            };
        });
    }

    render(): ReactNode {
        const loader = this.getLoader(this.state?.reports);
        if (loader !== null) return loader;

        const data = this.getTreeItems();

        return (
            <div>
                <TreeSelect
                    style={{ width: "100%" }}
                    value={this.state.currentlySelectedChart}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    treeData={data}
                    placeholder="Please select"
                    treeDefaultExpandAll
                    onChange={(v) => {
                        this.setState({ currentlySelectedChart: v });
                    }}
                />
            </div>
        );
    }
}
