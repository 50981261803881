import "../css/setup.scss";

import React from "react";
import { Alert, Button, Checkbox, Form, Input, Select, Typography } from "antd";
import { FormInstance } from "antd/lib/form";
import Page, { PageProps, PageState } from "./page";
import ClearLayout from "../layouts/clear_layout";
import Block from "../components/block";
import { myHistory } from "../components/my_history";
import { HttpRequestResult } from "../components/models/http/http_request_result";

interface SetupPageProps extends PageProps {}

interface SetupPageState extends PageState {
    databases: string[] | undefined;
    db?: HttpRequestResult;
    add?: HttpRequestResult;
    port: string;
}

export default class SetupPage extends Page<SetupPageProps, SetupPageState> {
    formRef = React.createRef<FormInstance>();

    defaultPorts: { [name: string]: number } = {
        mysql: 3306,
        pgsql: 5432,
    };

    public readonly state: Readonly<SetupPageState> = {
        databases: undefined,
        port: "3306",
    };

    onFinish = (values: { [name: string]: string }) => {
        if (this.state.databases) this.onAdd(values);
        else this.onCheck(values);
    };

    onCheck = (values: { [name: string]: string }) => {
        this.requestPost("/db/check", values, "db", false).then((data) => {
            this.setState({ databases: data.list });
        });
    };

    onAdd = (values: { [name: string]: string }) => {
        this.requestPost("/db/add", values, "add", false).then(() => {
            myHistory.push("/reports");
        });
    };

    dbTypeChange = (value: string) => {
        const port = this.defaultPorts[value] ?? undefined;
        if (port && this.formRef?.current)
            this.formRef.current.setFieldsValue({
                port,
            });
    };

    renderAuth() {
        const hideClass = this.state.databases ? "hide-form-fields" : "";
        const showClass = this.state.databases ? "" : "hidden";

        return (
            <div className="SetupPage form-block">
                <ClearLayout>
                    <Block>
                        <Typography>
                            <Typography.Title>Time to setup your first DataSource</Typography.Title>
                        </Typography>

                        {this.state?.db?.error && (
                            <Alert message={this.state?.db?.error} type="error" />
                        )}

                        <Form
                            ref={this.formRef}
                            layout="vertical"
                            name="basic"
                            onFinish={this.onFinish}
                            hideRequiredMark
                            scrollToFirstError
                        >
                            <div className={hideClass}>
                                <Form.Item
                                    label="Database type"
                                    name="type"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select database type",
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select database type"
                                        onChange={this.dbTypeChange}
                                    >
                                        <Select.Option value="mysql">MySql</Select.Option>
                                        <Select.Option value="pgsql">PostgreSQL</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Database host"
                                    name="host"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Host is required",
                                        },
                                    ]}
                                >
                                    <Input placeholder="db.company.com" />
                                </Form.Item>

                                <Form.Item
                                    label="Database port"
                                    name="port"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Port is required",
                                        },
                                    ]}
                                >
                                    <Input placeholder="3306" value={this.state.port} />
                                </Form.Item>

                                <Form.Item
                                    label="Username"
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Username is required",
                                        },
                                    ]}
                                >
                                    <Input placeholder="username" />
                                </Form.Item>

                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Password is required",
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </div>

                            <div className={showClass}>
                                <Alert message="Successfully connected" type="success" />

                                <Form.Item
                                    label="Select databases to add"
                                    name="databases"
                                    rules={[
                                        {
                                            required: !!this.state.databases,
                                            message: "Please select at least one database",
                                        },
                                    ]}
                                >
                                    <Checkbox.Group options={this.state.databases} />
                                </Form.Item>
                            </div>

                            <Form.Item>
                                <Button
                                    loading={
                                        this.state?.db?.loading === true ||
                                        this.state?.add?.loading === true
                                    }
                                    type="primary"
                                    htmlType="submit"
                                    block
                                >
                                    {this.state.databases ? "Add databases" : "Check connection"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Block>
                </ClearLayout>
            </div>
        );
    }
}
