import "../../css/report/chart_general_config.scss";

import React from "react";
import { Checkbox, Col, InputNumber, Row, Select } from "antd";
import MyComponent from "../my_component";
import MenuBlock from "../menu_block";
import { ChartConfig } from "../models/chart_config";

interface ChartGeneralConfigProps {
    chartConfig: ChartConfig;
    setChartConfig: (path: string, value: any) => void;
}

interface ChartGeneralConfigState {}

export default class ChartGeneralConfig extends MyComponent<
    ChartGeneralConfigProps,
    ChartGeneralConfigState
> {
    public readonly state: Readonly<ChartGeneralConfigState> = {};

    protected positions = ["top", "bottom", "left", "right"];

    checkbox = (
        path: string,
        title: string,
        checked: boolean,
    ): React.ReactNode => {
        return (
            <Checkbox
                checked={checked}
                onChange={(e) => {
                    this.props.setChartConfig(path, e.target.checked);
                }}
            >
                {title}
            </Checkbox>
        );
    };

    render() {
        return (
            <div className="chart-general_tab">
                <MenuBlock title="X axis">
                    <Row>
                        <Col span={8}>
                            {this.checkbox(
                                "options.xAxis.visible",
                                "Show X axis",
                                this.props.chartConfig.options.xAxis?.visible ??
                                    true,
                            )}
                        </Col>
                        <Col span={8}>
                            <label>Number of ticks:</label>
                            <InputNumber
                                min={2}
                                max={40}
                                size="small"
                                value={
                                    this.props.chartConfig.options.xAxis
                                        ?.ticks ?? 12
                                }
                                onChange={(v) => {
                                    this.props.setChartConfig(
                                        "options.xAxis.ticks",
                                        v,
                                    );
                                }}
                            />
                        </Col>
                    </Row>
                </MenuBlock>
                <MenuBlock title="Y axis">
                    <Row>
                        <Col span={8}>
                            {this.checkbox(
                                "options.yAxis.visible",
                                "Show Y axis",
                                this.props.chartConfig.options.yAxis?.visible ??
                                    true,
                            )}
                        </Col>
                        <Col span={8}>
                            <label>Number of ticks:</label>
                            <InputNumber
                                min={2}
                                max={40}
                                size="small"
                                value={
                                    this.props.chartConfig.options.yAxis
                                        ?.ticks ?? 20
                                }
                                onChange={(v) => {
                                    this.props.setChartConfig(
                                        "options.yAxis.ticks",
                                        v,
                                    );
                                }}
                            />
                        </Col>
                    </Row>
                </MenuBlock>
                <MenuBlock title="Legend">
                    <Row>
                        <Col span={8}>
                            {this.checkbox(
                                "options.legend.visible",
                                "Show legend",
                                this.props.chartConfig.options.legend
                                    ?.visible ?? true,
                            )}
                        </Col>
                        <Col span={8}>
                            <label>Position:</label>
                            <Select
                                size="small"
                                dropdownMatchSelectWidth={false}
                                value={
                                    this.props.chartConfig?.options?.legend
                                        ?.position ?? "bottom"
                                }
                                onChange={(value) =>
                                    this.props.setChartConfig(
                                        "options.legend.position",
                                        value,
                                    )
                                }
                            >
                                {this.positions.map((value) => (
                                    <Select.Option key={value} value={value}>
                                        {value}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </MenuBlock>
                <MenuBlock title="Tooltips">
                    {this.checkbox(
                        "options.tooltip.visible",
                        "Show tooltip",
                        true,
                    )}
                </MenuBlock>
                <MenuBlock title="Other">
                    {this.checkbox("options.animate", "Animate chart", true)}
                </MenuBlock>
            </div>
        );
    }
}
