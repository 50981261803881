import "../../css/report/chart_column_config.scss";

import React from "react";
import classnames from "classnames";
import { Button, Checkbox, Input, Select, Tooltip } from "antd";
import { CaretDownOutlined, CaretUpOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import * as _ from "lodash";
import MyComponent, { ComponentProps, ComponentState } from "../my_component";
import ColumnDropZone from "../column_drop_zone";
import Colorpicker from "../colorpicker";
import { DataColumn } from "../models/data_column";
import MenuBlock from "../menu_block";
import { ColumnConfig } from "../models/column_config";

interface ChartColumnConfigProps extends ComponentProps {
    datasetColumns: DataColumn[];

    yModes: { [key: string]: string };
    colorSet: string[];

    getChartConfig: (path: string, defaultValue: any) => any;
    setChartConfig: (path: string, value: any) => void;
}

interface ChartColumnConfigState extends ComponentState {
    dropZones: { [key: string]: ColumnDropZone };
}

export default class ChartColumnConfig extends MyComponent<
    ChartColumnConfigProps,
    ChartColumnConfigState
> {
    public readonly state: Readonly<ChartColumnConfigState> = {
        dropZones: {},
    };

    // https://github.com/alibaba/BizCharts/blob/master/doc/api/transform.md
    columnModes = {
        Average: "mean",
        Median: "median",
        Count: "count",
        "Distinct count": "", // TODO
        Maximum: "max",
        Minimum: "min",
        Sum: "sum",
        "Standard deviation": "standardDeviation",
        Variance: "variance",
        "Z-Score": "zScore",
    };

    getColorByIndex = (index: number): string => {
        const colorset = this.props.colorSet;

        return colorset[index % colorset.length];
    };

    getChartMode = () => this.props.getChartConfig("mode", "plot");

    onDragEnd = (result: DropResult) => {
        const { draggableId, destination } = result;

        if (
            !destination ||
            destination.droppableId === "dimensions" ||
            destination.droppableId === "measurements"
        )
            return;

        const dropZone: ColumnDropZone = this.state.dropZones[destination.droppableId] || null;
        if (!dropZone) return;

        dropZone.itemDropped(draggableId);
    };

    renderColumnsConfig = () => {
        const { yModes } = this.props;

        const mode = this.getChartMode();

        const xAxis: {
            [key: string]: ColumnConfig;
        } = this.props.getChartConfig("xAxis", {});
        const yAxis: {
            [key: string]: ColumnConfig;
        } = this.props.getChartConfig("yAxis", {});
        const colors: {
            [key: string]: ColumnConfig;
        } = this.props.getChartConfig("colors", {});

        return (
            <div>
                <ColumnDropZone
                    className="menu_block-axis_config"
                    title="X AXIS"
                    id="xAxis"
                    items={xAxis}
                    itemRender={() => {
                        return <></>;
                    }}
                    onItemsChange={(items) => {
                        this.props.setChartConfig("xAxis", items);
                    }}
                    onMounted={(ref) => {
                        this.setState((prevState) => {
                            const dropzones = prevState.dropZones;
                            dropzones.xAxis = ref;

                            return { dropZones: dropzones };
                        });
                    }}
                />
                <ColumnDropZone
                    className="menu_block-axis_config"
                    title="Y AXIS"
                    id="yAxis"
                    items={yAxis}
                    itemRender={(item, index, expanded, expand, change) => (
                        <>
                            <div
                                className={classnames({
                                    "drop-item-config-line": true,
                                    "drop-item-config-line-expanded": expanded,
                                })}
                            >
                                <Select
                                    dropdownClassName="config-param-menu"
                                    dropdownMatchSelectWidth={false}
                                    value={yAxis[item.key]?.agg || "mean"}
                                    onChange={(value) =>
                                        this.props.setChartConfig(`yAxis.${item.key}.agg`, value)
                                    }
                                >
                                    {Object.entries(this.columnModes).map(([name, value]) => {
                                        return (
                                            <Select.Option key={value} value={value}>
                                                {name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                                <Select
                                    dropdownClassName="config-param-menu"
                                    className="config-param"
                                    dropdownMatchSelectWidth={false}
                                    value={
                                        _.values(yModes).includes(yAxis[item.key]?.mode ?? "")
                                            ? yAxis[item.key]?.mode
                                            : _.values(yModes)[0]
                                    }
                                    onChange={(value) =>
                                        this.props.setChartConfig(`yAxis.${item.key}.mode`, value)
                                    }
                                >
                                    {Object.entries(yModes).map(([name, value]) => {
                                        return (
                                            <Select.Option key={value} value={value}>
                                                {this.renderYmode(name)}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                                <Button
                                    className="config-param"
                                    icon={expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
                                    onClick={expand}
                                />
                            </div>
                            {expanded && (
                                <div className="drop-item-config-expanded">
                                    <div className="drop-item-config-param">
                                        <div className="name">Name:</div>
                                        <div className="value">
                                            <Input
                                                value={
                                                    _.isNil(item.alias)
                                                        ? `${item.name}_${item.agg}`
                                                        : item.alias
                                                }
                                                onChange={(e) => change("alias", e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="drop-item-config-param">
                                        <div className="name">Labels:</div>
                                        <div className="value flex-value">
                                            <Checkbox
                                                checked={item.label}
                                                onChange={(e) => change("label", e.target.checked)}
                                            />
                                            <Input
                                                placeholder="Ex: 0,0.00"
                                                onChange={(e) =>
                                                    change("labelFormat", e.target.value)
                                                }
                                                value={
                                                    _.isNil(item.labelFormat)
                                                        ? "0a"
                                                        : item.labelFormat
                                                }
                                            />
                                            <Tooltip
                                                title={
                                                    <span>
                                                        Formatting according to{" "}
                                                        <a
                                                            href="https://numeraljs.com/"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                color: "rgba(255,255,255,0.9)",
                                                                textDecoration: "underline",
                                                            }}
                                                        >
                                                            numeraljs.com
                                                        </a>
                                                    </span>
                                                }
                                            >
                                                <QuestionCircleOutlined
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>

                                    <div className="drop-item-config-param">
                                        <div className="name">Color:</div>
                                        <div className="value flex-value">
                                            <Colorpicker
                                                color={item.color || this.getColorByIndex(index)}
                                                onChange={(v) => change("color", v)}
                                            />
                                            {item.color && (
                                                <div>
                                                    <a onClick={() => change("color", undefined)}>
                                                        Set default
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    onItemsChange={(items) => {
                        this.props.setChartConfig("yAxis", items);
                    }}
                    onMounted={(ref) => {
                        this.setState((prevState) => {
                            const dropzones = prevState.dropZones;
                            dropzones.yAxis = ref;

                            return { dropZones: dropzones };
                        });
                    }}
                    maxItems={mode === "plot" ? (_.keys(colors).length > 0 ? 1 : 10) : 1}
                />
                <ColumnDropZone
                    className="menu_block-axis_config"
                    enabled={mode === "plot"}
                    title="COLORS"
                    id="colors"
                    items={colors}
                    itemRender={() => {
                        return <></>;
                    }}
                    onItemsChange={(items) => {
                        this.props.setChartConfig("colors", items);
                    }}
                    onMounted={(ref) => {
                        this.setState((prevState) => {
                            const dropzones = prevState.dropZones;
                            dropzones.colors = ref;

                            return { dropZones: dropzones };
                        });
                    }}
                    minItems={0}
                    maxItems={_.keys(yAxis).length > 1 ? 0 : 1}
                />
            </div>
        );
    };

    renderColumnsList = () => {
        const dimensions: DataColumn[] = this.props.datasetColumns.filter(
            (col: DataColumn) => col.type === "dimension",
        );

        const measurements: DataColumn[] = this.props.datasetColumns.filter(
            (col: DataColumn) => col.type !== "dimension",
        );

        const columnBlocks: [string, string, DataColumn[]][] = [
            ["DIMENSION COLUMNS", "dimensions", dimensions],
            ["MEASUREMENTS COLUMNS", "measurements", measurements],
        ];

        return (
            <div className="columns-list">
                {columnBlocks.map(([title, blockId, columns]) => (
                    <MenuBlock title={title} key={blockId} className="menu_block-columns_list">
                        <Droppable droppableId={blockId}>
                            {(droppableProvided, droppableSnapshot) => (
                                <div
                                    ref={droppableProvided.innerRef}
                                    className={classnames({
                                        "dragged-over": droppableSnapshot.isDraggingOver,
                                    })}
                                    {...droppableProvided.droppableProps}
                                >
                                    {columns.map((col, index) => {
                                        return (
                                            <Draggable
                                                draggableId={col.name}
                                                key={`d-${col.name}`}
                                                index={index}
                                            >
                                                {(draggableProvided, draggableSnapshot) => (
                                                    <div
                                                        className={classnames({
                                                            "col-column": true,
                                                            "col-column-dragging":
                                                                draggableSnapshot.isDragging,
                                                        })}
                                                        ref={draggableProvided.innerRef}
                                                        {...draggableProvided.draggableProps}
                                                        {...draggableProvided.dragHandleProps}
                                                    >
                                                        <span className="col-column-name">
                                                            {col.name}
                                                        </span>
                                                        <span className="col-column-type">
                                                            {col.subtype}
                                                        </span>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </MenuBlock>
                ))}
            </div>
        );
    };

    render() {
        return (
            <div className="chart-columns_tab">
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <div className="chart_columns_tab-column chart_columns_tab-column-config">
                        {this.renderColumnsConfig()}
                    </div>
                    <div className="chart_columns_tab-column chart_columns_tab-column-list">
                        {this.renderColumnsList()}
                    </div>
                </DragDropContext>
            </div>
        );
    }

    renderYmode = (name: string): React.ReactNode => {
        if (name.substring(0, 2) === "--") {
            return <div className="select-subtype">{name.substring(2)}</div>;
        }

        return <div className="select-maintype">{name}</div>;
    };
}
