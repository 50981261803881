import "../css/param_dropdown.scss";
import React from "react";
import { Button, Dropdown, Menu } from "antd";
import { DownOutlined, PlusOutlined } from "@ant-design/icons/lib";
import { ParamConfig } from "./models/param_config";
import MyComponent, { ComponentProps, ComponentState } from "./my_component";
import { generateId } from "../utils/utils";
import ParamModal from "../pages/report/param_modal";
import { HttpParamSqlPreviewResponse } from "./models/http/http_param_sql_preview_response";

interface ParamDropdownProps extends ComponentProps {
    params: ParamConfig[];
    onParamSave: (param: ParamConfig) => void;
    onParamSelect: (param: ParamConfig) => void;

    onSqlPreview: (param: ParamConfig) => Promise<HttpParamSqlPreviewResponse>;
}

interface ParamDropdownState extends ComponentState {
    paramModalVisible: boolean;
    paramModalConfigKey: string | undefined;
}

export default class ParamDropdown extends MyComponent<ParamDropdownProps, ParamDropdownState> {
    public readonly state: Readonly<ParamDropdownState> = {
        paramModalVisible: false,
        paramModalConfigKey: undefined,
    };

    render = () => {
        const paramsMenu = (
            <Menu>
                {this.props.params.map((param) => (
                    <Menu.Item key={param.name + Math.random()} className="param-item">
                        <span
                            className="param-name"
                            onClick={() => this.props.onParamSelect(param)}
                        >
                            {param.name}
                        </span>
                        <span className="param-edit" onClick={() => this.editParam(param.key)}>
                            Edit
                        </span>
                    </Menu.Item>
                ))}
                <Menu.Divider />
                <Menu.Item className="add-item" key="add" onClick={() => this.createNewParam()}>
                    <PlusOutlined /> Add param
                </Menu.Item>
            </Menu>
        );

        const paramConfig: ParamConfig =
            this.state.paramModalConfigKey === undefined
                ? {
                      name: "Undefined param",
                      key: generateId(10),
                      type: "custom",
                      default: "",

                      haveMinimum: false,
                      haveMaximum: false,
                  }
                : this.props.params.filter((p) => p.key === this.state.paramModalConfigKey)[0];

        return (
            <div className="param-dropdown">
                {this.state.paramModalVisible && (
                    <ParamModal
                        allParams={this.props.params}
                        paramConfig={paramConfig}
                        onCancel={() => this.setState({ paramModalVisible: false })}
                        onSave={(newConfig) => {
                            this.props.onParamSave(newConfig);
                            this.setState({ paramModalVisible: false });
                        }}
                        onSqlPreview={this.props.onSqlPreview}
                    />
                )}
                <Dropdown
                    overlay={paramsMenu}
                    className="param-dropdown"
                    overlayClassName="param-dropdown-overlay"
                >
                    <Button
                        type="link"
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                    >
                        Parameters <DownOutlined />
                    </Button>
                </Dropdown>
            </div>
        );
    };

    editParam = (key: string) => {
        this.setState({
            paramModalConfigKey: key,
            paramModalVisible: true,
        });
    };

    createNewParam = () => {
        this.setState({
            paramModalConfigKey: undefined,
            paramModalVisible: true,
        });
    };
}
