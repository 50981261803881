import "../css/datasources.scss";

import React from "react";
import { Button, Empty, Modal, PageHeader, Table } from "antd";
import dayjs from "dayjs";
import MainLayout from "../layouts/main_layout";
import { myHistory } from "../components/my_history";
import AuthPage, { AuthPageProps, AuthPageState } from "./auth_page";
import { HttpRequestResult } from "../components/models/http/http_request_result";

interface DatasourcesPageProps extends AuthPageProps {}

interface DatasourcesPageState extends AuthPageState {
    loaded: boolean;
    new?: HttpRequestResult;
    request?: HttpRequestResult;
    updateSchema?: HttpRequestResult;
}

export default class DatasourcesPage extends AuthPage<DatasourcesPageProps, DatasourcesPageState> {
    loadData = () => {
        this.requestGet("/db/list", {}, "request", false).then(() => {
            this.setState({ loaded: true });
        });
    };

    componentDidMount() {
        this.loadData();
    }

    addNewDatasource = () => {
        this.requestGet("/reports/new", {}, "new", false)
            .then((data: any) => {
                alert(data.id);
                alert("XXX");
            })
            .catch((errMessage: string) => {
                if (this.state?.new?.errorData?.status === "noDatasources") {
                    Modal.warning({
                        title: "No data sources added to build reports",
                        content: (
                            <div>
                                <p>
                                    At least one data source should be added in order to build a
                                    report. Please add a data source.
                                </p>
                            </div>
                        ),
                        onOk() {
                            setTimeout(() => {
                                myHistory.push("/datasources");
                            }, 100);
                        },
                    });
                } else this.alertError(errMessage);
            });
    };

    updateSchema(id: number): void {
        this.requestGet("/db/schema-update", { id: id }, "updateSchema", false).then(() => {
            this.loadData();
        });
    }

    renderAuth() {
        return (
            <div className="DatasourcesPage">
                <MainLayout>
                    <PageHeader
                        title="Data sources"
                        extra={[
                            <Button
                                key="create-new-report"
                                type="primary"
                                onClick={this.addNewDatasource}
                                loading={this.state?.new?.loading}
                            >
                                Add new data source
                            </Button>,
                        ]}
                    />

                    {this.renderInner()}
                </MainLayout>
            </div>
        );
    }

    renderInner() {
        const loader = this.getLoader(this.state?.request);
        if (loader !== null) return loader;

        const data = this.state.request?.result ?? this.err("No data received");
        if (data.length === 0)
            return (
                <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{ height: 60 }}
                    description={<span>No data sources added yet</span>}
                >
                    <Button
                        type="primary"
                        onClick={this.addNewDatasource}
                        loading={this.state?.new?.loading}
                    >
                        Add data source
                    </Button>
                </Empty>
            );

        const schemaUpdating = this.state.updateSchema?.loading === true;

        const columns = [
            {
                title: "Name",
                dataIndex: "name",
                key: "name",
                render: (recordName: string, record: any) => {
                    return (
                        <div>
                            <strong>{recordName}</strong>
                            <br />
                            {record.type}
                        </div>
                    );
                },
            },
            {
                title: "Description",
                dataIndex: "description",
                key: "description",
                render: (recordDescription: string) => {
                    return <span>{recordDescription}</span>;
                },
            },
            {
                title: "Host",
                dataIndex: "host",
                key: "host",
            },
            {
                title: "Elements",
                key: "elements",
                render: (text: string, record: any) => <span>{record.tables} tables</span>,
            },
            {
                title: "Last query",
                dataIndex: "lastQuery",
                key: "lastQuery",
                render: (text: string, record: any) => {
                    try {
                        return dayjs(text, "YYYY-MM-DD HH:mm").from(dayjs(record.now));
                    } catch (e) {
                        return text;
                    }
                },
            },
            {
                title: "Schema updated",
                dataIndex: "lastSchemaUpdate",
                key: "lastSchemaUpdate",
                render: (text: string, record: any) => {
                    let lastUpdate = text;
                    try {
                        lastUpdate = dayjs(text, "YYYY-MM-DD HH:mm:ss").from(dayjs(record.now));
                        // eslint-disable-next-line no-empty
                    } catch (error) {}

                    return (
                        <>
                            {lastUpdate}
                            {schemaUpdating && (
                                <>
                                    <br />
                                    Updating...
                                </>
                            )}
                            {schemaUpdating || (
                                <>
                                    <br />
                                    <a
                                        onClick={() => {
                                            this.updateSchema(record.id);
                                        }}
                                    >
                                        Update now
                                    </a>
                                </>
                            )}
                        </>
                    );
                },
            },
            {
                title: "",
                key: "btns",
                render: () => {
                    return (
                        <>
                            <a
                                onClick={() => {
                                    this.alertError("Not implemented");
                                }}
                            >
                                Edit
                            </a>
                            &nbsp;
                            <a
                                onClick={() => {
                                    this.alertError("Not implemented");
                                }}
                            >
                                Remove
                            </a>
                        </>
                    );
                },
            },
        ];

        return <Table columns={columns} dataSource={data} />;
    }
}
