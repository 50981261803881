import "../../css/report/sql_subpage.scss";

import React from "react";
import { Button, Empty, Layout, Spin } from "antd";
import { CaretRightFilled, CloseCircleFilled, PlusCircleOutlined } from "@ant-design/icons";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Split from "react-split";
import SqlTable from "../../components/sql_table";
import MyComponent from "../../components/my_component";
import SqlEditor from "../../components/sql_editor/sql_editor";
import DbSelector from "../../components/db_selector";
import { HttpRequestResult } from "../../components/models/http/http_request_result";
import { HttpSqlRunResponse } from "../../components/models/http/http_sql_run_response";
import { QueryResult } from "../../components/models/query_result";
import { ParamConfig } from "../../components/models/param_config";
import ParamDropdown from "../../components/param_dropdown";
import { HttpParamSqlPreviewResponse } from "../../components/models/http/http_param_sql_preview_response";
import { DbContext, DbContextData } from "../../contexts/db_context";

interface SqlSubpageProps {
    sql: string;
    onChange: (value: string) => void;
    onRun: (value: string) => void;
    queryRun?: HttpRequestResult<HttpSqlRunResponse>;
    onAddChart: () => void;

    rightMenuCollapsed: boolean;
    secondsRunning?: number;
    params: ParamConfig[];

    onDbChange: (index: number, value: string) => void;
    onParamSave: (param: ParamConfig) => void;

    onSqlPreview: (param: ParamConfig) => Promise<HttpParamSqlPreviewResponse>;
}

interface SqlSubpageState {}

export default class SqlSubpage extends MyComponent<SqlSubpageProps, SqlSubpageState> {
    editor: SqlEditor | undefined;

    public readonly state: Readonly<SqlSubpageState> = {};

    getSqlCode = (): string => {
        return this.props.sql;
    };

    setSqlCode = (value: string) => {
        return this.props.onChange(value);
    };

    getQueryRun = () => {
        return this.props.queryRun;
    };

    runSql = () => {
        return this.props.onRun(this.getSqlCode());
    };

    renderTable = (data: QueryResult) => {
        return (
            <div className="sql-results">
                <div className="sql-results-toolbar">
                    <span className="results-count">Rows: {data.rows.length}</span>
                    <span className="add-chart-span">
                        <Button
                            type="primary"
                            icon={<PlusCircleOutlined />}
                            size="small"
                            onClick={this.props.onAddChart}
                            className="add-chart"
                        >
                            Add chart
                        </Button>
                    </span>
                </div>
                <div className="sql-table-big-container">
                    <SqlTable data={data} />
                </div>
            </div>
        );
    };

    render = () => {
        return (
            <DbContext.Consumer>
                {(dbContext) => (
                    <>
                        <Layout.Content>{this.renderCenter(dbContext)}</Layout.Content>
                        <Layout.Sider
                            theme="light"
                            collapsible
                            collapsed={this.props.rightMenuCollapsed}
                            className="right-menu sql-right-menu"
                            width={220}
                            collapsedWidth={0}
                            trigger={null}
                        >
                            {this.renderRightMenu(dbContext)}
                        </Layout.Sider>
                    </>
                )}
            </DbContext.Consumer>
        );
    };

    renderCenter = (dbContext: DbContextData) => {
        return (
            <div className="sql-subpage">
                <Split
                    minSize={150}
                    expandToMin
                    gutterSize={3}
                    gutterAlign="center"
                    snapOffset={0}
                    dragInterval={1}
                    direction="vertical"
                    className="split-box"
                >
                    <div className="top-panel">
                        <div className="title-container">
                            <div className="title">SQL Query</div>
                        </div>

                        <div className="top-toolbar">
                            <Button
                                className="run-button"
                                type="primary"
                                shape="round"
                                icon={<CaretRightFilled />}
                                onClick={this.runSql}
                                loading={this.getQueryRun()?.loading || false}
                            >
                                Run
                            </Button>
                            <ParamDropdown
                                params={this.props.params}
                                onParamSave={this.props.onParamSave}
                                onParamSelect={(param) => {
                                    if (this.editor) this.editor.pasteText(`{${param.name}}`);
                                }}
                                onSqlPreview={this.props.onSqlPreview}
                            />
                        </div>

                        <SqlEditor
                            schema={dbContext.dbSchema}
                            params={this.props.params}
                            value={this.getSqlCode()}
                            onChange={(value) => {
                                this.setSqlCode(value);
                            }}
                            onMount={(editor) => {
                                this.editor = editor;
                            }}
                        />
                    </div>
                    <div className="bottom-panel">{this.renderBottomPanel()}</div>
                </Split>
            </div>
        );
    };

    renderBottomPanel = () => {
        const queryRun = this.getQueryRun();

        if (queryRun?.loading)
            return (
                <div className="center-container">
                    <Spin size="large" tip={`Query is running... ${this.props.secondsRunning}s`} />
                </div>
            );

        if (queryRun?.error) {
            return (
                <div className="sql-error">
                    <div className="error-content">
                        <div className="error-title">
                            <CloseCircleFilled />
                            <div>Query error</div>
                        </div>
                        <code>{queryRun?.error}</code>
                    </div>
                </div>
            );
        }

        if (queryRun?.result) return this.renderTable(queryRun?.result?.result);

        return (
            <div className="center-container">
                <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{ height: 60 }}
                    description={<span>Run your query to see the data</span>}
                />
            </div>
        );
    };

    renderRightMenu = (dbContext: DbContextData) => {
        const tables = Object.values(dbContext.dbSchema.tables);

        return (
            <div>
                <div className="menu-header">
                    <span>DATABASE VIEWER</span>
                </div>

                <DbSelector
                    dbIndex={dbContext.dbIndex}
                    dbs={dbContext.dbSchemas}
                    onChange={this.props.onDbChange}
                />

                <div className="tables-list">
                    {tables.length === 0 ? (
                        <div className="no-tables">No tables</div>
                    ) : (
                        tables.map((table) => {
                            return (
                                <div className="table-schema" key={table.name}>
                                    {table.name}
                                </div>
                            );
                        })
                    )}
                </div>
            </div>
        );
    };
}
