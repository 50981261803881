import "../css/signup_confirm.scss";

import React from "react";
import { Alert, Button, Form, Input, Spin, Typography } from "antd";
import { match } from "react-router";
import Page, { PageProps, PageState } from "./page";
import ClearLayout from "../layouts/clear_layout";
import Block from "../components/block";
import { myHistory } from "../components/my_history";
import { HttpRequestResult } from "../components/models/http/http_request_result";

interface RouteParams {
    hash: string;
}

interface SignupConfirmState extends PageState {
    loaded: boolean;
    signup?: HttpRequestResult;
    request?: HttpRequestResult;
}

interface SignupConfirmProps extends PageProps {
    match: match<RouteParams>;
}

export default class SignupConfirmPage extends Page<SignupConfirmProps, SignupConfirmState> {
    public readonly state: Readonly<SignupConfirmState> = {
        loaded: false,
    };

    loadData() {
        this.requestPost(
            "/users/confirm",
            { hash: this.props?.match?.params?.hash ?? this.err("No hash specified") },
            "request",
            false,
        ).then(() => {
            this.setState({ loaded: true });
        });
    }

    componentDidMount() {
        this.loadData();
    }

    onFinish = (values: { [name: string]: string }) => {
        const data = values;
        data.hash = this.props.match.params.hash;

        this.requestPost("/users/finish-signup", data, "signup").then(() => {
            myHistory.push("/login");
        });
    };

    render() {
        if (!(this.state?.request?.loading === false))
            return (
                <div className="SignupConfirmPage form-block">
                    <ClearLayout>
                        <Block align="center">
                            <Spin size="large" />
                        </Block>
                    </ClearLayout>
                </div>
            );

        if (this.state?.request?.error !== null)
            return (
                <div className="SignupConfirmPage">
                    <ClearLayout>
                        <Block>
                            <Alert
                                message="Error"
                                description={this.state?.request?.error}
                                type="error"
                            />
                        </Block>
                    </ClearLayout>
                </div>
            );

        return (
            <div className="SignupConfirmPage">
                <ClearLayout>
                    <Block>
                        <Typography>
                            <Typography.Title>Finish your registration</Typography.Title>
                        </Typography>

                        <Form
                            layout="vertical"
                            name="basic"
                            onFinish={this.onFinish}
                            hideRequiredMark={false}
                            scrollToFirstError
                            initialValues={{
                                email: this.state?.request?.result?.email,
                            }}
                        >
                            <Form.Item label="Work email" name="email">
                                <Input readOnly />
                            </Form.Item>

                            <Form.Item
                                label="Your name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your work email!",
                                    },
                                ]}
                            >
                                <Input placeholder="John Snow" />
                            </Form.Item>

                            <Form.Item label="Company name" name="company">
                                <Input placeholder="Company Inc" />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your password!",
                                    },
                                    {
                                        min: 7,
                                        message: "Minimum password length is 7 symbols",
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item
                                label="Confirm password"
                                name="password2"
                                dependencies={["password"]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please confirm your password!",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue("password") === value) {
                                                return Promise.resolve();
                                            }
                                            // eslint-disable-next-line prefer-promise-reject-errors
                                            return Promise.reject(
                                                new Error("Entered passwords do not match!"),
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    loading={this.state?.signup?.loading === true}
                                    type="primary"
                                    htmlType="submit"
                                    block
                                >
                                    Sign Up
                                </Button>
                            </Form.Item>
                        </Form>
                    </Block>
                </ClearLayout>
            </div>
        );
    }
}
