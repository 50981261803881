import { Axis, Chart, Coordinate, Geom, Legend, Tooltip } from "bizcharts";
import React, { createRef, ErrorInfo, RefObject } from "react";
import DataSet from "@antv/data-set";
import { Result } from "antd";
import { View } from "@antv/data-set/lib/view";
import { ResizeObserver } from "resize-observer";
import * as _ from "lodash";
import equal from "fast-deep-equal";
import numeral from "numeral";
import { ChartType } from "../models/chart_type";
import { ChartOptions } from "../models/chart_options";
import { ColumnConfigTyped } from "../models/column_config_typed";
import MyComponent, { ComponentProps, ComponentState } from "../my_component";
import { GeometryLabelCfg } from "bizcharts/lib/interface";

interface MyChartProps extends ComponentProps {
    mode: ChartType;

    height: number;

    data: any[];

    xAxis?: ColumnConfigTyped;
    yAxis: ColumnConfigTyped[];
    colors: ColumnConfigTyped[];

    options: ChartOptions;

    onMount?: (self: MyChart) => void;
}

interface MyChartState extends ComponentState {
    error?: Error;
    errorInfo?: ErrorInfo;

    width: string;
    display: string;
}

export default class MyChart extends MyComponent<MyChartProps, MyChartState> {
    public static defaultProps: Partial<MyChartProps> = {
        mode: "plot",

        height: 400,

        data: [],

        xAxis: undefined,
        yAxis: [],
        colors: [],

        options: {},
    };

    public readonly state: Readonly<MyChartState> = {
        width: "100%",
        display: "block",
    };

    yColumns: string[] = [];

    xUniqueCount = 0;

    // g2?: Chart = undefined;

    mainDiv: RefObject<HTMLDivElement> = createRef();

    resizeObserver?: ResizeObserver;

    resizing: boolean = false;

    getYaxis = () => {
        if (this.props.mode !== "plot") return this.props.yAxis.slice(0, 1);

        return this.props.yAxis;
    };

    getData = (): View => {
        if (!this.props.xAxis) throw new Error("X axis is required");

        const columns = [
            this.props.xAxis.name,
            ...this.getYaxis().map((c) => c.name),
            ...this.props.colors.map((c) => c.name),
        ];
        this.yColumns = this.getYaxis().map((c) => `${c.name}_${c.agg}`);

        const groupBy = [this.props.xAxis.name, ...this.props.colors.map((c) => c.name)];

        let { data } = this.props;

        if (
            this.props.colors.length > 0 &&
            this.props.colors[0]!!.dataType!!.subtype === "boolean"
        ) {
            const colorName = this.props.colors[0].name;
            data = data.map((row) => {
                row[colorName] =
                    row[colorName] === "true" || row[colorName] == 1 ? "true" : "false";
                return row;
            });
        }

        const dv: View = new DataSet.View().source(data);

        dv.transform({
            type: "pick",
            fields: columns,
        });

        dv.transform({
            type: "aggregate",
            fields: this.getYaxis().map((c) => c.name),
            // @ts-ignore
            operations: this.getYaxis().map((c) => c.agg!!),
            as: this.yColumns,
            groupBy,
        });

        const xValues = new Set();
        dv.rows.forEach((row) => xValues.add(row[this.props.xAxis!!.name]));

        this.xUniqueCount = xValues.size;

        return dv;
    };

    getScale = (data: View): any => {
        const scale: any = {};

        let min: number = Number.MAX_VALUE;
        let max: number = -Number.MAX_VALUE;

        if (this.yColumns.length > 1) {
            data.rows.forEach((row, index) => {
                this.yColumns.forEach((colName, index) => {
                    const x = row[colName];
                    if (x > max) max = x;
                    if (x < min) min = x;
                });
            });
        }

        this.yColumns.forEach((colName, index) => {
            const yscale: any = {
                tickCount: this.props.options?.yAxis?.ticks || 20,
            };

            if (min !== Number.MAX_VALUE) {
                yscale.min = min;
                yscale.max = max;
            }

            const colConfig = this.getYConfig(index);
            const colType = colConfig.dataType.subtype;
            if (colType === "boolean") yscale.type = "cat";

            if (colConfig.alias) yscale.alias = colConfig.alias;

            scale[colName] = yscale;
        });

        const xType = this.props.xAxis!!.dataType!!.subtype;

        const xScale: any = {
            tickCount: Math.min(this.props.options?.xAxis?.ticks || 12, this.xUniqueCount),
            // formatter: this.formatXlabel,
            // type: "time",
            // mask: "MMM YYYY",

            label: {
                autoRotate: true,
            },
        };

        if (xType === "date") xScale.type = "time";

        scale[this.props.xAxis!!.name] = xScale;

        return scale;
    };

    _data?: View;

    _scale? = null;

    formatYlabel = (label: string) => label;

    formatXlabel = (label: string) => label;

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error,
            errorInfo,
        });
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.error) {
            return (
                <Result
                    className="my-chart-error"
                    status="error"
                    title="Error rendering chart"
                    subTitle="Please check and modify the chart config"
                />
            );
        }

        if (this._data === null) this._data = this.getData();

        if (this._scale === null) {
            this._scale = this.getScale(this._data!!);
        }

        const data = this._data;
        const scale = this._scale;

        return (
            <div
                className="my-chart"
                style={{ width: this.state.width || "100%" }}
                ref={this.mainDiv}
            >
                <div className="my-chart-inner" style={{ display: this.state.display }}>
                    <Chart
                        autoFit={true}
                        height={this.props.height}
                        data={data}
                        padding="auto"
                        scale={scale}
                        animate={this.props.options.animate}
                    >
                        {(this.props.options?.tooltip?.visible ?? true) && <Tooltip />}
                        {(this.props.options?.legend?.visible ?? true) && (
                            <Legend
                                visible
                                position={this.props.options.legend?.position ?? "bottom"}
                            />
                        )}
                        {this.props.mode === "pie" && (
                            <Coordinate
                                type="theta"
                                innerRadius={this.getShape(0) ? 0.7 : undefined}
                            />
                        )}
                        {this.props.mode === "plot" && (
                            <Axis
                                name={this.props.xAxis!!.name}
                                visible={this.props.options?.xAxis?.visible ?? true}
                            />
                        )}
                        {this.props.mode === "plot" && (
                            <Axis
                                name={this.props.colors.length > 0 ? "value" : this.yColumns[0]}
                                position="left"
                                visible={this.props.options?.yAxis?.visible ?? true}
                            />
                        )}
                        {this.props.mode === "plot" &&
                            this.props.colors.length === 0 &&
                            this.yColumns.length > 1 &&
                            this.yColumns
                                .slice(1)
                                .map((col) => (
                                    <Axis name={col} key={col} visible={false} position="right" />
                                ))}

                        {this.renderGeom(true)}
                        {/* {this.yColumns.length > 1 && <View data={data} scale={scale}> */}
                        {this.renderGeom(false)}
                        {/* </View>} */}
                    </Chart>
                </div>
            </div>
        );
    }

    renderGeom = (first: boolean) => {
        if (this.props.mode === "plot") return this.renderPlotGeom(first);

        return this.renderPieGeom(first);
    };

    renderPlotGeom = (first: boolean) => {
        const columns = first ? this.yColumns.slice(0, 1) : this.yColumns.slice(1);
        const firstIndex = first ? 0 : 1;

        return (
            <>
                {columns.map((colName, index) => {
                    return (
                        <Geom
                            key={colName + (firstIndex + index)}
                            type={this.getMode(firstIndex + index) ?? "area"}
                            position={`${this.props.xAxis!!.name}*${colName}`}
                            shape={this.getShape(firstIndex + index)}
                            color={
                                this.props.colors[firstIndex + index]
                                    ? [
                                          this.props.colors[firstIndex + index].name,
                                          this.props.options.colorSet,
                                      ]
                                    : this.getColor(firstIndex + index)
                            }
                            style={{
                                stroke: this.getColor(firstIndex + index),
                                fill:
                                    this.getMode(firstIndex + index) === "point"
                                        ? this.getColor(firstIndex + index)
                                        : null,
                            }}
                            adjust={
                                this.props.colors[firstIndex + index] &&
                                this.getMode(0) === "interval"
                                    ? [
                                          {
                                              type: "dodge",
                                              marginRatio: 1 / 8,
                                          },
                                      ]
                                    : undefined
                            }
                            label={
                                this.isLabelsShown(firstIndex + index)
                                    ? [
                                          colName,
                                          (text) => {
                                              return this.formatLabel(
                                                  text,
                                                  null,
                                                  index,
                                                  this.getLabelFormatter(firstIndex + index),
                                              );
                                          },
                                      ]
                                    : false
                            }
                        />
                    );
                })}
            </>
        );
    };

    formatLabel = (text: string, item: any, index: number, formatter: string): GeometryLabelCfg => {
        let formatted = text;

        try {
            if (formatter === "auto") {
                formatted = numeral(text).value().toString();
            } else {
                formatted = numeral(text).format(formatter);
            }
        } catch (e) {}

        return {
            content: formatted,
        };
    };

    renderPieGeom = (first: boolean) => {
        const columns = first ? this.yColumns.slice(0, 1) : this.yColumns.slice(1);
        const firstIndex = first ? 0 : 1;

        return (
            <>
                {columns.map((colName, index) => {
                    return (
                        <Geom
                            key={colName + firstIndex + index}
                            type={this.getMode(firstIndex + index) ?? "area"}
                            position={colName}
                            color={this.props.xAxis!!.name}
                            label={
                                this.isLabelsShown(firstIndex + index)
                                    ? [
                                          colName,
                                          (text) => {
                                              return this.formatLabel(
                                                  text,
                                                  null,
                                                  index,
                                                  this.getLabelFormatter(firstIndex + index),
                                              );
                                          },
                                      ]
                                    : false
                            }
                        />
                    );
                })}
            </>
        );
    };

    getYConfig(index: number): ColumnConfigTyped {
        return this.getYaxis()[index];
    }

    getColorSet = (): string[] => {
        return (
            this.props.options?.colorSet ?? [
                "#1890FF",
                "#2FC25B",
                "#FACC14",
                "#223273",
                "#8543E0",
                "#13C2C2",
                "#3436C7",
                "#F04864",
            ]
        );
    };

    getColor = (index: number) => {
        const colorset = this.getColorSet();
        return _.get(this.getYaxis(), `${index}.color`, colorset[index % colorset.length]);
    };

    getMode = (index: number) => this.getYConfig(index).mode;

    isLabelsShown = (index: number): boolean => this.getYConfig(index).label || false;

    getShape = (index: number) => this.getYConfig(index).shape;

    getLabelFormatter = (index: number): string => this.getYConfig(index).labelFormat || "0.00";

    componentDidMount() {
        if (this.props.onMount) this.props.onMount(this);

        this.resizeObserver = new ResizeObserver(() => this.resize());
        this.resizeObserver.observe(this.mainDiv.current!!);
    }

    resize() {
        if (this.resizing) return;

        this.resizing = true;

        this.setState({ display: "none" }, () => {
            const width = this.mainDiv.current!!.clientWidth;
            this.setState({ display: "block", width: width.toString() }, () => {
                this.setState({ width: "100%" }, () => {
                    this.resizing = false;
                });
            });
        });
    }

    shouldComponentUpdate(
        nextProps: Readonly<MyChartProps>,
        nextState: Readonly<MyChartState>,
        nextContext: any,
    ): boolean {
        const names = ["mode", "data", "xAxis", "yAxis", "colors", "options"];
        let changed = false;

        names.forEach((name) => {
            // @ts-ignore
            if (!equal(this.props[name], nextProps[name])) {
                changed = true;
            }
        });

        if (changed) {
            this._scale = undefined;
            this._data = undefined;
        }

        return true;
    }

    componentWillUnmount() {
        if (this.resizeObserver) this.resizeObserver.disconnect();
    }
}
