import "../css/reports.scss";

import React from "react";
import { Button, Empty, Input, Modal, PageHeader, Table } from "antd";
import { Link } from "react-router-dom";
import * as _ from "lodash";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import MainLayout from "../layouts/main_layout";
import { myHistory } from "../components/my_history";
import AuthPage, { AuthPageProps, AuthPageState } from "./auth_page";
import { HttpRequestResult } from "../components/models/http/http_request_result";
import { HttpReportsListResponse } from "../components/models/http/http_reports_list_response";

dayjs.extend(relativeTime);

interface ReportsPageProps extends AuthPageProps {}

interface ReportsPageState extends AuthPageState {
    loaded?: boolean;
    name: string;
    description: string;
    new?: HttpRequestResult;
    request?: HttpRequestResult<HttpReportsListResponse>;
    saving?: HttpRequestResult;

    editedRow?: number;
}

export default class ReportsPage extends AuthPage<ReportsPageProps, ReportsPageState> {
    public readonly state: Readonly<ReportsPageState> = {
        editedRow: undefined,
        name: "",
        description: "",
    };

    loadData = () => {
        this.requestGet("/reports/list", {}, "request", false).then(() => {
            this.setState({ loaded: true });
        });
    };

    componentDidMount() {
        this.loadData();
    }

    createNewReport = () => {
        this.requestGet("/reports/new", {}, "new", false)
            .then((data: any) => {
                myHistory.push(`/reports/${data.id}`);
            })
            .catch((errMessage) => {
                if (this.state?.new?.errorData?.status === "noDatasources") {
                    Modal.warning({
                        title: "No Data sources added to build reports",
                        content: (
                            <div>
                                <p>
                                    At least one data source should be added in order to build a
                                    report. Please add a data source.
                                </p>
                            </div>
                        ),
                        okText: "Go to data sources",
                        onOk() {
                            myHistory.push("/datasources");
                        },
                    });
                } else this.alertError(errMessage);
            });
    };

    renderAuth() {
        return (
            <div className="ReportsPage">
                <MainLayout>
                    <PageHeader
                        title="Reports"
                        extra={[
                            <Button
                                key="create-new-report"
                                type="primary"
                                onClick={this.createNewReport}
                                loading={this.state?.new?.loading}
                            >
                                Create new report
                            </Button>,
                        ]}
                    />

                    {this.renderInner()}
                </MainLayout>
            </div>
        );
    }

    changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ name: e.target.value });
    };

    changeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ description: e.target.value });
    };

    saveRow = () => {
        this.requestPost(
            `/reports/${this.state.editedRow}/edit`,
            { name: this.state.name, description: this.state.description },
            "saving",
            false,
        )
            .then(() => {
                const index = _.findIndex(
                    this.state.request!!.result,
                    (report: any) => report.id === this.state.editedRow,
                );
                this.setStateValue(`request.result.${index}.name`, this.state.name);
                this.setStateValue(`request.result.${index}.description`, this.state.description);
            })
            .catch((error) => {
                this.alertError(`Error saving report: ${error}`);
            })
            .finally(() => {
                this.setState({
                    editedRow: undefined,
                });
            });
    };

    renderInner() {
        const loader = this.getLoader(this.state?.request);
        if (loader !== null) return loader;

        const data = this.state.request?.result ?? this.err("No data received");

        if (data.length === 0)
            return (
                <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{ height: 60 }}
                    description={<span>No reports created yet</span>}
                >
                    <Button
                        type="primary"
                        onClick={this.createNewReport}
                        loading={this.state?.new?.loading}
                    >
                        Create Now
                    </Button>
                </Empty>
            );

        const { editedRow, name, description } = this.state;
        const loading = this.state.saving?.loading ?? false;

        const columns = [
            {
                title: "Name",
                dataIndex: "name",
                key: "name",
                render: (recordName: string, record: any) => {
                    if (record.id === editedRow)
                        return <Input value={name} onChange={this.changeName} disabled={loading} />;

                    return <Link to={`/reports/${record.id}`}>{recordName}</Link>;
                },
            },
            {
                title: "Description",
                dataIndex: "description",
                key: "description",
                render: (recordDescription: string, record: any) => {
                    if (record.id === editedRow)
                        return (
                            <Input
                                value={description}
                                onChange={this.changeDescription}
                                disabled={loading}
                            />
                        );

                    return <span>{recordDescription}</span>;
                },
            },
            {
                title: "Elements",
                key: "elements",
                render: (text: string, record: any) => (
                    <span>
                        {record.queries} queries | {record.charts} charts
                    </span>
                ),
            },
            {
                title: "Created by",
                dataIndex: "createdBy",
                key: "createdBy",
            },
            {
                title: "Created at",
                dataIndex: "createdAt",
                key: "createdAt",
            },
            {
                title: "Last edited",
                dataIndex: "modifiedAt",
                key: "modifiedAt",
                render: (text: string, record: any) => {
                    return dayjs(text, "YYYY-MM-DD HH:mm:ss").from(dayjs(record.now));
                },
            },
            {
                title: "",
                key: "btns",
                render: (text: string, record: any) => {
                    if (record.id === editedRow)
                        return (
                            <Button
                                type="primary"
                                size="small"
                                onClick={this.saveRow}
                                loading={loading}
                            >
                                Save
                            </Button>
                        );

                    if (editedRow !== undefined) return <></>;

                    return (
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                                myHistory.push(`/reports/${record.id}`);
                            }}
                        >
                            Open
                        </Button>
                    );
                },
            },
            {
                title: "",
                key: "btns2",
                render: (text: string, record: any) => {
                    if (record.id === editedRow)
                        return (
                            <a
                                onClick={() => {
                                    this.setState({ editedRow: undefined });
                                }}
                            >
                                Cancel
                            </a>
                        );

                    if (editedRow !== undefined) return <></>;

                    return (
                        <a
                            onClick={() => {
                                this.setState({
                                    editedRow: record.id,
                                    name: record.name,
                                    description: record.description,
                                });
                            }}
                        >
                            Rename
                        </a>
                    );
                },
            },
            {
                title: "",
                key: "btns3",
                render: () => {
                    if (editedRow !== undefined) return <></>;

                    return (
                        <a
                            onClick={() => {
                                this.alertError("Not implemented");
                            }}
                        >
                            Remove
                        </a>
                    );
                },
            },
        ];

        return <Table columns={columns} dataSource={data} />;
    }
}
