import "../css/block.scss";

import React from "react";
import MyComponent from "./my_component";

interface BlockProps {
    align: "left" | "center" | "right";
}

export default class Block extends MyComponent<BlockProps, {}> {
    public static defaultProps: Partial<BlockProps> = {
        align: "center",
    };

    render() {
        return (
            <div className="block" style={{ textAlign: this.props.align }}>
                {this.props.children}
            </div>
        );
    }
}
