import "../css/layout_clear.scss";

import React from "react";
import { Layout } from "antd";
import MyComponent from "../components/my_component";
import logo from "../assets/logo.svg";

export default class ClearLayout extends MyComponent<{}, {}> {
    render() {
        return (
            <Layout className="clear-layout">
                <Layout.Header>
                    <div className="logo">
                        <img src={logo} alt="Logo" />
                    </div>
                </Layout.Header>
                <Layout.Content>{this.props.children}</Layout.Content>
                <Layout.Footer>
                    Copyright &copy; 2020, Dataact Inc
                </Layout.Footer>
            </Layout>
        );
    }
}
