class Config {
    apiHost = Config.isDev()
        ? "http://localhost:8080"
        : "https://api.dataact.io";

    webHost = Config.isDev()
        ? "http://localhost:3000"
        : "https://panel.dataact.io";

    websiteHost = "https://dataact.io/";

    dev = Config.isDev();

    static isDev() {
        return (
            window.location.hostname === "localhost" ||
            window.location.hostname === "127.0.0.1"
        );
    }
}

const config = new Config();

// @ts-ignore
window.config = config;

export default config;
