import React from "react";
import { Select } from "antd";
import * as _ from "lodash";
import MyComponent from "./my_component";
import { DbSchema } from "./models/db_schema";

interface DbSelectorProps {
    onChange: (index: number, value: string) => void;
    dbs: DbSchema[];
    dbIndex?: number;
}

export default class DbSelector extends MyComponent<DbSelectorProps, {}> {
    getDbOptions = () => {
        return this.props.dbs.map((db) => ({
            value: db.dbName,
            label: db.dbName,
        }));
    };

    getSelectedIndex = () => this.props.dbIndex || 0;

    getSelectedValue = () => this.getDbOptions()[this.getSelectedIndex()].value;

    onChange = (value: string) => {
        const index = _.findIndex(this.props.dbs, (db: DbSchema) => {
            return value === db.dbName;
        });

        this.props.onChange(index, value);
    };

    render() {
        return (
            <div className="db-selector">
                <Select
                    onChange={this.onChange}
                    value={this.getSelectedValue()}
                    placeholder="Please select"
                >
                    {this.getDbOptions().map((db) => (
                        <Select.Option key={db.value} value={db.value}>
                            {db.label}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        );
    }
}
