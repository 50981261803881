import "../css/report.scss";

import React from "react";
import { Layout, Menu, Spin } from "antd";
import {
    ConsoleSqlOutlined,
    LeftOutlined,
    LineChartOutlined,
    PieChartOutlined,
    PlusOutlined,
    RightOutlined,
} from "@ant-design/icons";
import { type } from "@antv/dw-analyzer";
import { match } from "react-router";
import * as _ from "lodash";
import logo from "../assets/logo.svg";
import SqlSubpage from "./report/sql_subpage";
import ChartSubpage from "./report/chart_subpage";
import { generateId } from "../utils/utils";
import AuthPage, { AuthPageProps, AuthPageState } from "./auth_page";
import { DataColumn } from "../components/models/data_column";
import { HttpRequestResult } from "../components/models/http/http_request_result";
import { ChartConfig } from "../components/models/chart_config";
import { HttpSqlRunResponse } from "../components/models/http/http_sql_run_response";
import { HttpReportConfig } from "../components/models/http/http_report_config";
import { DbSchema } from "../components/models/db_schema";
import { ParamConfig } from "../components/models/param_config";
import { HttpParamSqlPreviewResponse } from "../components/models/http/http_param_sql_preview_response";
import { DbContext } from "../contexts/db_context";

interface RouteParams {
    id: string;
}

interface ReportPageProps extends AuthPageProps {
    match: match<RouteParams>;
}

interface ReportPageState extends AuthPageState {
    menuSelectedKey: string;

    leftMenuCollapsed: boolean;
    rightMenuCollapsed: boolean;

    sql?: string;

    loaded: boolean;
    config?: HttpRequestResult<HttpReportConfig>;
    queryRun?: HttpRequestResult<HttpSqlRunResponse>;

    queryRunning: boolean;
    queryOk: boolean;
    queryError?: string;

    launchSeconds: number;
    seconds?: number;

    selectedDbIndex: number;

    columns: { [name: string]: DataColumn };
}

export default class ReportPage extends AuthPage<ReportPageProps, ReportPageState> {
    interval?: NodeJS.Timeout;

    seconds = 0;

    public readonly state: Readonly<ReportPageState> = {
        menuSelectedKey: "menu-sql",
        leftMenuCollapsed: false,
        rightMenuCollapsed: false,

        loaded: false,
        queryRunning: false,
        queryOk: false,
        queryError: undefined,

        launchSeconds: 0,
        selectedDbIndex: 0,

        columns: {},
    };

    loadData = () => {
        this.requestGet("/reports/get/", { id: this.props.match.params.id }, "config", false)
            .then((data) => {
                this.setState({ loaded: true });
                this.setSelectedDbName(data.database);
            })
            .catch(() => {});
    };

    startCounter = () => {
        this.interval = setInterval(() => {
            this.seconds++;
            if (this.state.queryRunning) this.setState({ seconds: this.seconds });
        }, 1000);
    };

    componentDidMount() {
        this.loadData();
        this.startCounter();
    }

    componentWillUnmount() {
        if (this.interval) clearInterval(this.interval);
    }

    renderAuth() {
        const { leftMenuCollapsed, rightMenuCollapsed, menuSelectedKey } = this.state;

        let logoClasses = "logo-container";
        if (leftMenuCollapsed) logoClasses += " cropped";

        let rightExpanderClasses = "right-expander";
        if (rightMenuCollapsed) rightExpanderClasses += " cropped";
        else rightExpanderClasses += " full";

        return (
            <div className="ReportPage">
                <Layout>
                    <Layout.Header>
                        <div className={logoClasses}>
                            <div className="logo">
                                <img src={logo} alt="Logo" />
                            </div>
                        </div>
                        {this.state?.config?.loading === false &&
                            this.state?.config?.error === null && (
                                <div
                                    className={rightExpanderClasses}
                                    onClick={this.toggleRightMenu}
                                    style={{
                                        width: rightMenuCollapsed
                                            ? undefined
                                            : menuSelectedKey === "menu-sql"
                                            ? 220
                                            : 460,
                                    }}
                                >
                                    {this.state.rightMenuCollapsed ? (
                                        <LeftOutlined />
                                    ) : (
                                        <RightOutlined />
                                    )}
                                </div>
                            )}
                    </Layout.Header>
                    {this.renderInner()}
                </Layout>
            </div>
        );
    }

    toggleRightMenu = () => {
        this.setState((prevState) => ({ rightMenuCollapsed: !prevState.rightMenuCollapsed }));
    };

    renderInner() {
        const loader = this.getLoader(this.state?.config);
        if (loader !== null) return loader;

        return (
            <Layout className="content-layout">
                <Layout.Sider
                    theme="light"
                    collapsible
                    collapsed={this.state.leftMenuCollapsed}
                    onCollapse={(collapsed) => {
                        this.setState({ leftMenuCollapsed: collapsed });
                    }}
                    className="left-menu"
                    width={220}
                >
                    {this.renderLeftMenu()}
                </Layout.Sider>
                <DbContext.Provider
                    value={{
                        dbSchemas: this.getConfig().schemas,
                        dbSchema: this.getSelectedDbSchema(),
                        dbName: this.getSelectedDbName(),
                        dbIndex: this.state.selectedDbIndex,
                    }}
                >
                    {this.renderContent()}
                </DbContext.Provider>
            </Layout>
        );
    }

    renderLeftMenu = () => {
        return (
            <Menu mode="inline" selectedKeys={[this.state.menuSelectedKey]}>
                <div className="menu-header">
                    <span>REPORT ELEMENTS</span>
                    {/* <div className="icon"> */}
                    {/*  {this.state?.addQuery?.loading ? <Spin indicator={loaderIcon}/> : */}
                    {/*    <PlusCircleFilled onClick={this.addQuery}/>} */}
                    {/* </div> */}
                </div>
                <Menu.Item
                    className="menu-item menu-item-sql"
                    key="menu-sql"
                    onClick={() => {
                        this.changeTo("sql");
                    }}
                >
                    {this.state.queryRunning ? (
                        <div className="anticon">
                            <Spin size="small" />
                        </div>
                    ) : (
                        <ConsoleSqlOutlined />
                    )}
                    <span>SQL</span>
                </Menu.Item>
                <Menu.Divider />
                <div className="menu-header">
                    <span>Charts</span>
                </div>
                {this.getCharts().map((chart, index) => {
                    return (
                        <Menu.Item
                            className="menu-item menu-item-chart"
                            key={chart.key}
                            onClick={() => {
                                this.changeTo(`chart-${index}`);
                            }}
                        >
                            {chart.type === "pie" ? <PieChartOutlined /> : <LineChartOutlined />}
                            <span>{chart.name}</span>
                        </Menu.Item>
                    );
                })}
                {!this.state.queryRunning && this.state.queryOk && this.state.queryError === null && (
                    <Menu.Item
                        className="menu-item menu-add-element"
                        key="menu-add-chart"
                        onClick={this.addChart}
                    >
                        <PlusOutlined />
                        <span>Add chart</span>
                    </Menu.Item>
                )}
            </Menu>
        );
    };

    changeTo = (to: string) => {
        if (to.includes("chart") && !this.state.queryOk) this.runSql();

        this.setState({ menuSelectedKey: `menu-${to}` });
    };

    getSqlCode = () => {
        return this.getStateValue("sql", this.getConfig().query.body);
    };

    setSqlCode = (code: string) => {
        this.setState({ sql: code });
    };

    runSql = (sql = this.getSqlCode(), dbName = this.getSelectedDbName()) => {
        if (this.state.queryRunning) return;

        const cleanSql = sql.trim();

        if (!cleanSql) {
            this.alertError("SQL query is required");
            this.changeTo("sql");
            return;
        }

        this.setState((state: ReportPageState) => {
            // @ts-ignore
            const self: ReportPage = this as ReportPage;

            const newState = state;

            _.set(newState, "config.result.query.body", sql);

            newState.launchSeconds = self.seconds;
            newState.seconds = self.seconds;
            newState.queryRunning = true;
            newState.queryOk = false;
            newState.queryError = undefined;

            return newState;
        });

        this.requestPost<HttpSqlRunResponse>(
            `/reports/${this.props.match.params.id}/query/run`,
            { query: sql, db: dbName },
            "queryRun",
            false,
        )
            .then((data) => {
                try {
                    const { rows } = data.result;

                    const dimensions = ["null", "boolean", "date", "string"];

                    const columnsTypes = _.reduce(
                        data.result.columns,
                        (result: { [key: string]: DataColumn }, column: string, index: number) => {
                            const columnData = rows.map((row) => row[index]);
                            const info = type(columnData);
                            // eslint-disable-next-line no-param-reassign
                            result[column] = {
                                name: column,
                                type: dimensions.includes(info.recommendation)
                                    ? "dimension"
                                    : "measurement",
                                subtype: info.recommendation,
                            };
                            return result;
                        },
                        {},
                    );

                    this.setState({
                        columns: columnsTypes,
                        queryRunning: false,
                        queryOk: true,
                        queryError: undefined,
                    });
                } catch (e) {
                    this.setState({
                        queryRunning: false,
                        queryOk: false,
                        queryError: e.toString(),
                    });
                    this.changeTo("sql");
                }
            })
            .catch((err: string) => {
                this.setState({
                    queryRunning: false,
                    queryOk: false,
                    queryError: err.toString(),
                });
                this.changeTo("sql");
            });
    };

    getParams = (): ParamConfig[] => this.state.config?.result?.params || [];

    getCharts = (): ChartConfig[] => this.state.config?.result?.charts || [];

    addChart = () => {
        const charts = this.getCharts();
        charts.push({
            name: `Chart #${charts.length + 1}`,
            key: generateId(10),
            type: "plot",
            options: {},
        });

        this.setStateValue("config.result.charts", charts, () => {
            this.changeTo(`chart-${this.getCharts().length - 1}`);
        });
    };

    onSelectedDbChange = (index: number, value: string) => {
        this.setSelectedDbIndex(index);

        const { dbName } = this.state.config!!.result!!.schemas[index];

        if (this.getStateValue("config.result.database") === dbName) return;

        this.setStateValue("config.result.database", dbName);

        this.requestPost(
            `/reports/${this.props.match.params.id}/edit`,
            { db: dbName },
            "dbSave",
            false,
        ).catch((error) => {
            this.alertError(`Error saving database: ${error}`);
        });
    };

    getSelectedDbSchema = () => {
        return this.getConfig().schemas[this.state.selectedDbIndex];
    };

    getSelectedDbName = () => {
        return this.getSelectedDbSchema().dbName;
    };

    setSelectedDbIndex = (index: number) => {
        this.setState({
            selectedDbIndex: index,
        });
    };

    setSelectedDbName = (name: string) => {
        const index = _.findIndex(this.getConfig().schemas, (db: DbSchema) => {
            return db.dbName === name;
        });
        this.setSelectedDbIndex(index);
    };

    getConfig = (): HttpReportConfig => {
        return this.state.config!!.result!!;
    };

    getData = () => {
        return this.state?.queryRun?.result?.result!!;
    };

    renderSqlPage = () => {
        return (
            <SqlSubpage
                sql={this.getSqlCode()}
                onChange={(code) => this.setSqlCode(code)}
                onRun={(code) => this.runSql(code)}
                queryRun={this.state.queryRun}
                secondsRunning={
                    (this.state.seconds ?? this.state.launchSeconds) - this.state.launchSeconds
                }
                onAddChart={this.addChart}
                params={this.getParams()}
                onDbChange={this.onSelectedDbChange}
                rightMenuCollapsed={this.state.rightMenuCollapsed}
                onParamSave={(paramConfig) => {
                    const params = this.getParams();

                    const index = _.findIndex(
                        params,
                        (p: ParamConfig) => p.key === paramConfig.key,
                    );
                    if (index === -1) {
                        params.push(paramConfig);
                    } else {
                        params[index] = paramConfig;
                    }

                    this.setStateValue("config.result.params", params, () => {
                        this.saveParam(paramConfig.key);
                    });
                }}
                onSqlPreview={(param) => {
                    return this.requestPost<HttpParamSqlPreviewResponse>(
                        `/reports/${this.props.match.params.id}/param/${param.key}/run-sql`,
                        { query: param.optionsSql },
                        "previewParamSql",
                        false,
                    );
                }}
            />
        );
    };

    renderChartPage = (index: number): React.ReactNode => {
        const charts = this.getCharts();

        const data = this.state.queryRun?.result?.result;
        if (!data || !this.state.columns || Object.keys(this.state.columns).length === 0) {
            return (
                <div className="full-page-loader">
                    {this.getLoader(this.state.queryRun, "Executing SQL query...")}
                </div>
            );
        }

        return (
            <ChartSubpage
                data={data}
                chartConfig={charts[index]}
                rightMenuCollapsed={this.state.rightMenuCollapsed}
                datasetColumns={this.state.columns}
                onChange={(config) => {
                    const { key } = config;
                    const saveIndex = _.findIndex(
                        this.getConfig().charts,
                        (chart: ChartConfig) => chart.key === key,
                    );
                    this.setStateValue(`config.result.charts.${saveIndex}`, config, () => {
                        this.saveChart(key);
                    });
                }}
            />
        );
    };

    saveChart = (chartKey: string) => {
        const configIndex = _.findIndex(
            this.getConfig().charts,
            (chart: ChartConfig) => chart.key === chartKey,
        );
        const config = this.getConfig().charts[configIndex];

        this.requestPost(
            `/reports/${this.props.match.params.id}/chart/${chartKey}/save`,
            config,
            `chartSave${configIndex}`,
            false,
        ).catch(() => {});
    };

    renderParamPage = (index: number): React.ReactNode => {
        return <div />;
    };

    saveParam = (paramKey: string) => {
        const configIndex = _.findIndex(
            this.getConfig().params,
            (param: ParamConfig) => param.key === paramKey,
        );
        const config = this.getConfig().params[configIndex];

        this.requestPost(
            `/reports/${this.props.match.params.id}/param/${paramKey}/save`,
            config,
            `paramSave${configIndex}`,
            false,
        ).catch(() => {});
    };

    renderQueryFailure = (): React.ReactNode => {
        return (
            <div className="full-page-loader">
                {this.getLoader(this.state.queryRun, "Executing SQL query...")}
            </div>
        );
    };

    renderContent(): React.ReactNode {
        const menuItem = this.state.menuSelectedKey.substr(5);

        if (menuItem === "sql") return this.renderSqlPage();

        if (menuItem.substr(0, 5) === "chart") {
            if (!this.state.queryOk) {
                return this.renderQueryFailure();
            }

            const chartIndex = Number(menuItem.substr(6));
            return this.renderChartPage(chartIndex);
        }

        const paramIndex = Number(menuItem.substr(6));

        return this.renderParamPage(paramIndex);
    }
}
