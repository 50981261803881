import "../css/menu_block.scss";

import React from "react";
import MyComponent from "./my_component";

interface MenuBlockProps {
    title: string | React.ReactNode;
    headerRight?: string | React.ReactNode;
    className?: string;
}

interface MenuBlockState {}

export default class MenuBlock extends MyComponent<
    MenuBlockProps,
    MenuBlockState
> {
    public readonly state: Readonly<MenuBlockState> = {};

    render() {
        return (
            <div className={`menu_block ${this.props.className || ""}`}>
                <div className="menu_block-head">
                    <div className="menu_block-head-left">
                        {this.props.title}
                    </div>
                    <div className="menu_block-head-right">
                        {this.props.headerRight || <></>}
                    </div>
                </div>
                <div className="menu_block-body">{this.props.children}</div>
            </div>
        );
    }
}
