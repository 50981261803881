import "../css/colorpicker.scss";

import * as React from "react";
import { ColorResult, SwatchesPicker } from "react-color";
import MyComponent, { ComponentProps, ComponentState } from "./my_component";

interface ColorpickerProps extends ComponentProps {
    onChange: (hex: string) => void;
    color?: string;
}

interface ColorpickerState extends ComponentState {
    pickerShown: boolean;
}

export default class Colorpicker extends MyComponent<ColorpickerProps, ColorpickerState> {
    state = {
        pickerShown: false,
    };

    showHidePicker = () => {
        this.setState((prevState) => ({ pickerShown: !prevState.pickerShown }));
    };

    handleClose = () => {
        this.setState({ pickerShown: false });
    };

    handleChange = (v: ColorResult) => {
        this.props.onChange(v.hex);
    };

    render(): React.ReactNode {
        return (
            <div className="colorpicker">
                <div
                    className="colorpicker-color"
                    style={{ backgroundColor: this.props.color }}
                    onClick={this.showHidePicker}
                >
                    {this.state.pickerShown && (
                        <div className="picker-full">
                            <div className="picker-cover" onClick={this.handleClose} />
                            <SwatchesPicker color={this.props.color} onChange={this.handleChange} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
