import React from "react";
import { isLoggedIn } from "../utils/auth";
import { myHistory } from "../components/my_history";
import Page, { PageProps, PageState } from "./page";

export interface AuthPageProps extends PageProps {}

export interface AuthPageState extends PageState {}

export default abstract class AuthPage<
    P extends AuthPageProps,
    S extends AuthPageState
> extends Page<P, S> {
    abstract renderAuth(): React.ReactNode;

    getClassName = () => {
        return this.constructor.name;
    };

    render() {
        if (!isLoggedIn()) myHistory.push("/signin");

        return <div className={this.getClassName()}>{this.renderAuth()}</div>;
    }
}
