import axios from "axios";

const AUTH_TOKEN_KEY = "auth_token";
const USER_EMAIL_KEY = "email_auth";

// export function loginGoogle() {
//     if (config.dev)
//         window.location.href = "http://localhost:8090/admin/admin-auth";
//     else
//         window.location.href = "https://api.jetcompass.com/admin/admin-auth";
// }

function clearAuthToken() {
    localStorage.removeItem(AUTH_TOKEN_KEY);
}

function clearEmailToken() {
    localStorage.removeItem(USER_EMAIL_KEY);
}

export function logout() {
    clearAuthToken();
    clearEmailToken();
    axios.defaults.headers.common.Authorization = null;
    window.location.href = "/login";
}

// export function requireAuth(nextState, replace) {
//   if (!isLoggedIn()) {
//     replace({pathname: '/'});
//   }
// }

export function getAuthToken() {
    // if (config.dev)
    //   return "dev";

    return localStorage.getItem(AUTH_TOKEN_KEY);
}

export function isLoggedIn() {
    const idToken = getAuthToken();
    return !!idToken && idToken !== "null";
}

export function getUserEmail() {
    // if (config.dev)
    //   return "dev";

    return localStorage.getItem(USER_EMAIL_KEY);
}

// Helper function that will allow us to extract the access_token and id_token
// export function getParameterByName(name) {
//   let match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash);
//   return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
// }

// Get and store access_token in local storage
export function setTokens(email: string, authToken: string) {
    localStorage.setItem(USER_EMAIL_KEY, email);
    localStorage.setItem(AUTH_TOKEN_KEY, authToken);

    axios.defaults.headers.common.Authorization =
        // eslint-disable-next-line prefer-template
        "Basic " + btoa(`${email}:${authToken}`);
}

export function checkAuth() {
    if (isLoggedIn())
        axios.defaults.headers.common.Authorization =
            // eslint-disable-next-line prefer-template
            "Basic " + btoa(`${getUserEmail()}:${getAuthToken()}`);
}
