import "../css/signup.scss";

import React from "react";
import { Alert, Button, Form, Input, Typography } from "antd";
import { Store } from "antd/lib/form/interface";
import Page, { PageProps, PageState } from "./page";
import ClearLayout from "../layouts/clear_layout";
import Block from "../components/block";
import { HttpRequestResult } from "../components/models/http/http_request_result";

interface SignupPageState extends PageState {
    signup?: HttpRequestResult;
    status?: string;
}

interface SignupPageProps extends PageProps {}

export default class SignupPage extends Page<SignupPageState, SignupPageProps> {
    public readonly state: Readonly<SignupPageState> = {
        status: "",
    };

    onFinish = (values: Store) => {
        this.requestPost("/users/signup", values, "signup").then(() => {
            this.setState({ status: "ok" });
        });
    };

    render() {
        if (this.state.status === "ok")
            return (
                <div className="SignupPage">
                    <ClearLayout>
                        <Block>
                            <Typography>
                                <Typography.Title>
                                    Welcome to Dataact
                                </Typography.Title>
                            </Typography>
                            Please check your email for the confirmation email
                        </Block>
                    </ClearLayout>
                </div>
            );

        if (this.state.status !== "")
            return (
                <div className="SignupPage">
                    <ClearLayout>
                        <Block>
                            <Typography>
                                <Typography.Title>
                                    Welcome to Dataact
                                </Typography.Title>
                            </Typography>
                            <Alert
                                message="Error"
                                description={this.state?.status}
                                type="error"
                            />
                        </Block>
                    </ClearLayout>
                </div>
            );

        return (
            <div className="SignupPage form-block">
                <ClearLayout>
                    <Block>
                        <Typography>
                            <Typography.Title>
                                Welcome to Dataact
                            </Typography.Title>
                        </Typography>

                        <Form
                            layout="vertical"
                            name="basic"
                            onFinish={this.onFinish}
                            hideRequiredMark
                            scrollToFirstError
                        >
                            <Form.Item
                                label="Work email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please input your work email!",
                                    },
                                    {
                                        type: "email",
                                        message:
                                            "Please input your valid work email!",
                                    },
                                ]}
                            >
                                <Input placeholder="me@company.com" />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    loading={
                                        this.state?.signup?.loading === true
                                    }
                                    type="primary"
                                    htmlType="submit"
                                    block
                                >
                                    Sign Up
                                </Button>
                            </Form.Item>
                        </Form>
                    </Block>
                </ClearLayout>
            </div>
        );
    }
}
