import React from "react";
import { DbSchema } from "../components/models/db_schema";

export type DbContextData = {
    dbSchemas: DbSchema[];
    dbSchema: DbSchema;
    dbName: string;
    dbIndex: number;
};

// @ts-ignore
export const DbContext = React.createContext<DbContextData>(undefined);
