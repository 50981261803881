import "./css/style.scss";

import React, { Component } from "react";
import { Router } from "react-router-dom";
import Routes from "./routes";
import { myHistory } from "./components/my_history";
import { checkAuth } from "./utils/auth";

export class App extends Component<{}, {}> {
    constructor(props: {}) {
        super(props);
        checkAuth();
    }

    render(): React.ReactNode {
        return (
            <Router history={myHistory}>
                <Routes />
            </Router>
        );
    }
}
