import "../css/login.scss";

import React from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Form, Input, Typography } from "antd";
import { Link } from "react-router-dom";
import Page, { PageProps, PageState } from "./page";
import { isLoggedIn, setTokens } from "../utils/auth";
import { myHistory } from "../components/my_history";
import ClearLayout from "../layouts/clear_layout";
import Block from "../components/block";
import { HttpRequestResult } from "../components/models/http/http_request_result";
import { HttpLoginResponse } from "../components/models/http/http_login_response";

interface LoginPageProps extends PageProps {}

interface LoginPageState extends PageState {
    signin?: HttpRequestResult;
}

class LoginPage extends Page<LoginPageProps, LoginPageState> {
    onFinish = (values: any) => {
        this.requestPost<HttpLoginResponse>(
            "/users/signin",
            values,
            "signin",
            false,
        )
            .then((data: HttpLoginResponse) => {
                // Successful auth
                setTokens(data.email, data.token);
                myHistory.push("/reports");
            })
            .catch(() => {});
    };

    render() {
        const url = new URL(window.location.href);

        if (url.searchParams.get("token")) {
            setTokens(
                url.searchParams.get("token") ?? this.err("No token set"),
                url.searchParams.get("name") ?? this.err("No name set"),
            );

            myHistory.push("/orders");
            return null;
        }

        if (isLoggedIn()) {
            myHistory.push("/reports");
            return null;
        }

        return (
            <div className="LoginPage form-block">
                <ClearLayout>
                    <Block>
                        <Typography>
                            <Typography.Title>
                                Welcome to Dataact
                            </Typography.Title>
                        </Typography>
                        {this.state?.signin?.error && (
                            <Alert
                                message={this.state?.signin?.error}
                                type="error"
                            />
                        )}
                        <Form
                            layout="vertical"
                            name="basic"
                            onFinish={this.onFinish}
                            hideRequiredMark
                            scrollToFirstError
                        >
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Email is required",
                                    },
                                    {
                                        type: "email",
                                        message: "Email is invalid",
                                    },
                                ]}
                            >
                                <Input
                                    prefix={
                                        <UserOutlined className="site-form-item-icon" />
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Password is required",
                                    },
                                ]}
                            >
                                <Input.Password
                                    prefix={
                                        <LockOutlined className="site-form-item-icon" />
                                    }
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    loading={
                                        this.state?.signin?.loading === true
                                    }
                                    type="primary"
                                    htmlType="submit"
                                    block
                                >
                                    Sign in
                                </Button>
                            </Form.Item>
                        </Form>
                        <Divider dashed />
                        <div className="text-center">
                            New to Dataact? <Link to="/signup">Sign up!</Link>
                        </div>
                    </Block>
                </ClearLayout>
            </div>
        );
    }
}

export default LoginPage;
