import { CancellationToken, editor, IRange, languages, Position } from "monaco-editor";
import { DbSchema } from "../models/db_schema";
import { ParamConfig } from "../models/param_config";

export class SqlCompletitionProvider implements languages.CompletionItemProvider {
    schema: DbSchema;

    params: ParamConfig[];

    constructor(schema: DbSchema, params: ParamConfig[]) {
        this.schema = schema;
        this.params = params;
    }

    getParamsProposals(filterText: string, range: IRange): languages.CompletionItem[] {
        const allParams: languages.CompletionItem[] = this.params.map(
            (param): languages.CompletionItem => {
                return {
                    label: param.name,
                    kind: languages.CompletionItemKind.Constant,
                    documentation: "The Lodash library exported as Node.js modules.",
                    insertText: `${param.name}}`,
                    range: range,
                };
            },
        );

        const filteredParams = allParams.filter((p) =>
            typeof p.label === "string" ? p.label.startsWith(filterText.substr(1)) : true,
        );

        return filteredParams;
    }

    createDependencyProposals(
        range: IRange,
        fullText: string,
        textUntilPosition: string,
    ): languages.CompletionItem[] {
        const lastOpenBrackets = textUntilPosition.lastIndexOf("{");
        const lastCloseBrackets = textUntilPosition.lastIndexOf("}");

        const isOpenBrackets = lastOpenBrackets > -1 && lastCloseBrackets < lastOpenBrackets;

        if (isOpenBrackets) {
            const textAfterOpenBrackets = textUntilPosition.substr(lastOpenBrackets);

            const newRange: IRange = {
                startLineNumber: range.startLineNumber,
                endLineNumber: range.endLineNumber,
                startColumn: range.startColumn,
                endColumn: range.endColumn,
            };

            return this.getParamsProposals(textAfterOpenBrackets, newRange);
        }

        const listOfSuggestions: languages.CompletionItem[] = [];

        Object.values(this.schema.tables).forEach((table) => {
            listOfSuggestions.push({
                label: table.name,
                kind: languages.CompletionItemKind.Folder,
                documentation: "The Lodash library exported as Node.js modules.",
                insertText: table.name,
                range: range,
            });

            Object.values(table.columns).forEach((column) => {
                listOfSuggestions.push({
                    label: `${table.name}.${column.name}`,
                    kind: languages.CompletionItemKind.File,
                    documentation: "The Lodash library exported as Node.js modules.",
                    insertText: `${table.name}.${column.name}`,
                    range: range,
                });
            });
        });

        return listOfSuggestions;
    }

    provideCompletionItems(
        model: editor.ITextModel,
        position: Position,
        context: languages.CompletionContext,
        token: CancellationToken,
    ): languages.ProviderResult<languages.CompletionList> {
        const textUntilPosition = model.getValueInRange({
            startLineNumber: 1,
            startColumn: 1,
            endLineNumber: position.lineNumber,
            endColumn: position.column,
        });

        const fullText = model.getValue();

        const lastWord = model.getWordUntilPosition(position);

        const range: IRange = {
            startLineNumber: position.lineNumber,
            endLineNumber: position.lineNumber,
            startColumn: lastWord.startColumn,
            endColumn: lastWord.endColumn,
        };

        return {
            suggestions: this.createDependencyProposals(range, fullText, textUntilPosition),
        };
    }
}
