import React, { Component } from "react";
import * as _ from "lodash";
import { Button, message, Result, Spin } from "antd";
import axios, { AxiosError, AxiosResponse } from "axios";
import { logout } from "../utils/auth";
import config from "./config";
import { ServerResponse } from "./models/http/server_response";
import { HttpRequestResult } from "./models/http/http_request_result";

export interface ComponentProps {}

export interface ComponentState {
    reloading?: boolean;
}

export default class MyComponent<
    P extends ComponentProps,
    S extends ComponentState
> extends Component<P, S> {
    err = (str: string): never => {
        throw new Error(str);
    };

    getLoader = (
        request?: HttpRequestResult,
        loadingText: string = "Loading data...",
    ): React.ReactNode => {
        if (!(request?.loading === false))
            return (
                <div className="data-loader">
                    <Spin size="large" tip={loadingText} />
                </div>
            );

        if (request?.error !== null)
            return (
                <div className="data-load-error">
                    <Result
                        status="error"
                        title="Error loading data"
                        subTitle={request?.error}
                        extra={[
                            <Button
                                type="primary"
                                key="reload"
                                disabled={this.state?.reloading === true}
                                onClick={() => {
                                    this.setState({ reloading: true });
                                    window.location.reload(true);
                                }}
                            >
                                Reload data
                            </Button>,
                        ]}
                    />
                </div>
            );

        return null;
    };

    setStateValue(paramPath: string, value: any, callback?: () => void) {
        this.setState((state) => {
            return _.set(state ?? {}, paramPath, value);
        }, callback);
    }

    getStateValue(paramPath: string, defaultValue: any = undefined) {
        return _.get(this.state, paramPath, defaultValue);
    }

    handleAxiosThen = (
        name: string,
        response: AxiosResponse<ServerResponse>,
        resolve: (data: any) => void,
        reject: (err: string) => void,
        showAlert = true,
    ) => {
        if (response?.data?.status !== "ok") {
            if (showAlert) this.alertError(`Error: ${response.data.message}`);

            this.setStateValue(name, {
                error: response.data.message,
                loading: false,
                result: null,
            });

            reject(response?.data?.message ?? "No message specified");

            return;
        }

        this.setStateValue(name, {
            error: null,
            loading: false,
            result: response.data.data,
        });

        resolve(response.data.data);
    };

    handleAxiosCatch = (
        name: string,
        error: AxiosError<ServerResponse>,
        resolve: (data: any) => void,
        reject: (err: string) => void,
        showAlert = true,
    ) => {
        if (error?.response?.status === 401) {
            logout();
            return;
        }

        let errorMessage;
        if (error?.response?.data?.message) {
            errorMessage = error?.response?.data?.message || "Unknown error";
        } else {
            errorMessage = error.message;
        }

        if (showAlert) this.alertError(errorMessage);

        this.setStateValue(name, {
            error: errorMessage,
            loading: false,
            result: null,
            errorData: error?.response?.data,
        });

        reject(errorMessage);
    };

    requestGet<T = any>(
        url: string,
        getParams: { [key: string]: any },
        name = "request",
        showAlert = true,
    ): Promise<T> {
        this.setStateValue(`${name}.loading`, true);

        return new Promise<T>((resolve, reject) => {
            axios
                .get(config.apiHost + url, { params: getParams })
                .then((response) =>
                    this.handleAxiosThen(name, response, resolve, reject, showAlert),
                )
                .catch((error) => this.handleAxiosCatch(name, error, resolve, reject, showAlert));
        });
    }

    requestPost<T = any>(
        url: string,
        params: any,
        name: string = "request",
        showAlert: boolean = true,
    ): Promise<T> {
        this.setStateValue(`${name}.loading`, true);

        return new Promise<T>((resolve, reject) => {
            axios
                .post(config.apiHost + url, params)
                .then((response) =>
                    this.handleAxiosThen(name, response, resolve, reject, showAlert),
                )
                .catch((error) => this.handleAxiosCatch(name, error, resolve, reject, showAlert));
        });
    }

    alertNormal = (text: string) => message.info(text);

    alertSuccess = (text: string) => message.success(text);

    alertError = (text: string) => message.error(text);
}
