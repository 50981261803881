import React from "react";
import MyComponent, { ComponentProps, ComponentState } from "../components/my_component";

export interface PageProps extends ComponentProps {}

export interface PageState extends ComponentState {}

export default abstract class Page<P extends PageProps, S extends PageState> extends MyComponent<
    P,
    S
> {}
