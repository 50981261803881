import "../css/dashboard.scss";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import React from "react";
import { Layout } from "antd";
import { match } from "react-router";
import logo from "../assets/logo.svg";
import AuthPage, { AuthPageProps, AuthPageState } from "./auth_page";
import { HttpRequestResult } from "../components/models/http/http_request_result";
import { HttpDashboardConfig } from "../components/models/http/http_dashboard_config";
import GridLayout from "react-grid-layout";
import ChartSelector from "../components/dashboard/chart-selector";

const Grid = GridLayout.WidthProvider(GridLayout);

interface RouteParams {
    id: string;
}

interface DashboardPageProps extends AuthPageProps {
    match: match<RouteParams>;
}

interface DashboardPageState extends AuthPageState {
    rightMenuCollapsed: boolean;
    config?: HttpRequestResult<HttpDashboardConfig>;
    currentLayout: GridLayout.Layout[] | undefined;
}

export default class DashboardPage extends AuthPage<DashboardPageProps, DashboardPageState> {
    public readonly state: Readonly<DashboardPageState> = {
        rightMenuCollapsed: false,
        currentLayout: undefined,
    };

    loadData = () => {
        this.requestGet(`/dashboards/${this.props.match.params.id}/get/`, {}, "config", false)
            .then((data) => {})
            .catch(() => {});
    };

    componentDidMount() {
        this.loadData();
    }

    renderAuth() {
        return (
            <div className="DashboardPage">
                <Layout>
                    <Layout.Header>
                        <div className="logo-container">
                            <div className="logo">
                                <img src={logo} alt="Logo" />
                            </div>
                        </div>
                    </Layout.Header>
                    {this.renderInner()}
                </Layout>
            </div>
        );
    }

    toggleRightMenu = () => {
        this.setState((prevState) => ({ rightMenuCollapsed: !prevState.rightMenuCollapsed }));
    };

    renderInner() {
        const loader = this.getLoader(this.state?.config);
        if (loader !== null) return loader;

        return (
            <Layout className="content-layout">
                <Layout.Content>{this.renderContent()}</Layout.Content>
                <Layout.Sider
                    theme="light"
                    collapsible
                    collapsed={this.state.rightMenuCollapsed}
                    onCollapse={(collapsed) => {
                        this.setState({ rightMenuCollapsed: collapsed });
                    }}
                    className="left-menu"
                    width={220}
                >
                    {this.renderRightMenu()}
                </Layout.Sider>
            </Layout>
        );
    }

    renderContent() {
        const layout = this.state.currentLayout ??
            this.state.config?.result?.layout?.layoutConfig ?? [
                { i: "a", x: 0, y: 0, w: 1, h: 2, static: true },
                { i: "b", x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4 },
                { i: "c", x: 4, y: 0, w: 1, h: 2 },
            ];

        return (
            <Grid
                className="layout"
                layout={layout}
                cols={12}
                rowHeight={30}
                onLayoutChange={(l) => {
                    this.setState({ currentLayout: l });
                }}
            >
                <div key="a">a</div>
                <div key="b">b</div>
                <div key="c">c</div>
            </Grid>
        );
    }

    renderRightMenu() {
        return (
            <div className="right-items">
                <div className="add-item-block chart">
                    <h5>Add chart</h5>
                    <div className="chart-lists">
                        <ChartSelector />
                    </div>
                </div>
                <div className="add-item-block html">Add HTML</div>
                <div className="add-item-block markdown">Add Markdown</div>
                <div className="add-item-block filters">Add filters</div>
            </div>
        );
    }
}
