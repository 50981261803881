import "../../css/sql.scss";

import React, { createRef, RefObject } from "react";
import { ResizeObserver } from "resize-observer";
import MonacoEditor from "react-monaco-editor";
import * as monaco from "monaco-editor";
import { editor } from "monaco-editor";
import equal from "fast-deep-equal";
import MyComponent from "../my_component";
import { registerDataactSql } from "./sql_config";
import { DbSchema } from "../models/db_schema";
import { ParamConfig } from "../models/param_config";

interface SqlEditorProps {
    height: number;
    value: string;
    options: monaco.editor.IEditorConstructionOptions;

    params?: ParamConfig[];
    schema: DbSchema;

    onChange: (value: string) => void;
    onMount?: (self: SqlEditor) => void;
}

interface SqlEditorState {
    sqlInit: boolean;
}

export default class SqlEditor extends MyComponent<SqlEditorProps, {}> {
    monaco: monaco.editor.IStandaloneCodeEditor | undefined;

    resizeObserver?: ResizeObserver;

    mainDiv: RefObject<HTMLDivElement> = createRef();

    state = {
        sqlInit: false,
    };

    public static defaultProps: Partial<SqlEditorProps> = {
        height: 0,
        value: "",
        options: {},
    };

    render() {
        const options: monaco.editor.IEditorConstructionOptions = this.props.options || {};

        let style = {};

        if (this.props.height > 0) {
            style = { height: this.props.height };
        }

        this.registerSql();

        return (
            <div className="sql-editor" ref={this.mainDiv} style={style}>
                <MonacoEditor
                    {...style}
                    language="data-sql"
                    theme="data-theme"
                    value={this.props.value}
                    options={options}
                    onChange={this.props.onChange}
                    editorDidMount={(m) => {
                        this.monaco = m;
                    }}
                />
            </div>
        );
    }

    registerSql = (force: boolean = false) => {
        if (!this.state.sqlInit || force) {
            registerDataactSql(this.props.schema, this.props.params ?? []);
            if (!force) this.setState({ sqlInit: true });
        }
    };

    componentDidMount() {
        if (this.props.onMount) this.props.onMount(this);

        if (this.props.height === 0) {
            this.resizeObserver = new ResizeObserver(() => this.resize());
            this.resizeObserver.observe(this.mainDiv.current ?? this.err("No resizeObserver set"));
        }

        if (this.monaco) this.monaco.layout();
    }

    componentDidUpdate(
        prevProps: Readonly<SqlEditorProps>,
        prevState: Readonly<SqlEditorState>,
        snapshot?: any,
    ) {
        if (
            !equal(this.props.schema, prevProps.schema) ||
            !equal(this.props.params, prevProps.params)
        ) {
            this.registerSql(true);
        }
    }

    componentWillUnmount() {
        if (this.resizeObserver) this.resizeObserver.disconnect();
    }

    getMonaco(): editor.IStandaloneCodeEditor {
        return this.monaco ?? this.err("Monaco is not set");
    }

    resize = () => {
        if (this.monaco) this.monaco.layout();
    };

    pasteText = (text: string) => {
        const line = this.getMonaco().getPosition();
        if (!line) return;

        const range = new monaco.Range(line.lineNumber, line.column, line.lineNumber, line.column);
        const id = { major: 1, minor: 1 };
        const op = {
            identifier: id,
            range,
            text,
            forceMoveMarkers: true,
        };
        this.getMonaco().executeEdits("my-source", [op]);
    };
}
