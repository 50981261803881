import * as monaco from "monaco-editor";
import { getSqlLanguage, sqlConf } from "./sql_language";
import { ParamConfig } from "../models/param_config";
import { DbSchema } from "../models/db_schema";
import { SqlCompletitionProvider } from "./sql_completition";

export function registerDataactSql(schema: DbSchema, params: ParamConfig[]) {
    monaco.languages.register({ id: "data-sql" });

    monaco.languages.setMonarchTokensProvider("data-sql", getSqlLanguage(schema, params));
    monaco.languages.registerCompletionItemProvider(
        "data-sql",
        new SqlCompletitionProvider(schema, params),
    );
    monaco.languages.setLanguageConfiguration("data-sql", sqlConf);

    // @ts-ignore
    monaco.editor.defineTheme("data-theme", {
        base: "vs-dark",
        inherit: true,
        rules: [
            { token: "str", foreground: "00ff00" },

            { token: "table", foreground: "fff0f0" },
            { token: "table_column", foreground: "fff0f0" },

            { token: "placeholder", foreground: "ff0000", fontStyle: "bold" },
        ],
    });
}
