import "../css/layout_main.scss";

import * as React from "react";
import { Layout, Menu } from "antd";
import { SelectInfo } from "rc-menu/lib/interface";
import MyComponent from "../components/my_component";
import logo from "../assets/logo.svg";
import { myHistory } from "../components/my_history";

export default class MainLayout extends MyComponent<Record<string, any>, Record<string, any>> {
    render() {
        let path = myHistory.location.pathname.substr(1);
        if (path.includes("/"))
            // eslint-disable-next-line prefer-destructuring
            path = path.split("/")[0];

        return (
            <Layout className="main-layout">
                <Layout.Header>
                    <div className="logo">
                        <img src={logo} alt="Logo" />
                    </div>
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={[path]}
                        onSelect={(item: SelectInfo) => {
                            myHistory.push(`/${item.key}`);
                        }}
                    >
                        <Menu.Item key="reports">Reports</Menu.Item>
                        <Menu.Item key="dashboards">Dashboards</Menu.Item>
                        <Menu.Item key="tasks">Tasks</Menu.Item>
                        <Menu.Item key="datasources">Data sources</Menu.Item>
                    </Menu>
                </Layout.Header>
                <Layout.Content>{this.props.children}</Layout.Content>
                <Layout.Footer>Copyright &copy; 2020, Dataact Inc</Layout.Footer>
            </Layout>
        );
    }
}
