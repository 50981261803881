import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";
import LoginPage from "./pages/login";
import SignupPage from "./pages/signup";
import SignupConfirmPage from "./pages/signup_confirm";
import ReportsPage from "./pages/reports";
import SetupPage from "./pages/setup";
import DatasourcesPage from "./pages/datasources";
import ReportPage from "./pages/report";
import DashboardsPage from "./pages/dashboards";
import DashboardPage from "./pages/dashboard";

const Routes = (props: { [key: string]: any } = {}) => (
    <>
        <Switch {...props}>
            <Route path="/reports" exact component={ReportsPage} />
            <Route path="/reports/:id" exact component={ReportPage} />

            <Route path="/datasources" exact component={DatasourcesPage} />
            <Route path="/dashboards" exact component={DashboardsPage} />
            <Route path="/dashboards/:id" exact component={DashboardPage} />

            {/* <Route path="/tasks" exact component={TasksPage} /> */}

            <Route path="/setup" exact component={SetupPage} />

            <Route path="/signup" exact component={SignupPage} />
            <Route path="/confirm/:hash" exact component={SignupConfirmPage} />

            <Route path="*" exact component={LoginPage} />
        </Switch>
    </>
);

export default Routes;
