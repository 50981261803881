import "../../css/report/param_modal.scss";

import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    Popconfirm,
    Row,
    Select,
    Table,
    Tabs,
    Tooltip,
    Typography,
} from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import React, { RefObject } from "react";
import { FormInstance } from "antd/lib/form";
import moment, { Moment } from "moment";
import * as _ from "lodash";
import MyComponent, { ComponentProps, ComponentState } from "../../components/my_component";
import { ParamConfig, ParamConfigType } from "../../components/models/param_config";
import SqlEditor from "../../components/sql_editor/sql_editor";
import { HttpParamSqlPreviewResponse } from "../../components/models/http/http_param_sql_preview_response";
import DateParamConfig from "../../components/models/date_param_config";
import DateParamValueConfig, { dateFormat } from "../../components/models/date_param_value_config";
import { DbContext } from "../../contexts/db_context";

interface ParamModalProps extends ComponentProps {
    allParams: ParamConfig[];
    paramConfig: ParamConfig;

    onSave: (data: ParamConfig) => void;
    onCancel: () => void;

    onSqlPreview: (param: ParamConfig) => Promise<HttpParamSqlPreviewResponse>;
}

interface ParamModalState extends ComponentState {
    paramConfigCurrent: ParamConfig;

    optionTitle: string;
    optionValue: string;
    sqlCode: string;
    sqlLoading: boolean;

    sqlResultPreviewed: boolean;
}

export default class ParamModal extends MyComponent<ParamModalProps, ParamModalState> {
    public readonly state: Readonly<ParamModalState> = {
        paramConfigCurrent: this.props.paramConfig,
        optionTitle: "",
        optionValue: "",
        sqlCode: "",
        sqlLoading: false,

        sqlResultPreviewed: (this.props.paramConfig?.optionsSql || "") !== "",
    };

    formRef = React.createRef<FormInstance>();

    optionsFormRef: RefObject<FormInstance> = React.createRef();

    modes: { [name: string]: ParamConfigType } = {
        Select: "select",
        "SQL Select": "sql-select",
        Date: "date",
        "Date period": "date-period",
        Number: "number",
        "Custom string": "custom",
    };

    setParamConfig = (name: string, value: any) => {
        this.setStateValue(`paramConfigCurrent.${name}`, value);
    };

    handleCancel = () => {
        this.props.onCancel();
    };

    save = () => {
        this.props.onSave(this.state.paramConfigCurrent);
    };

    clickSubmit = () => {
        if (this.state.sqlLoading) {
            this.alertError("Please wait for SQL Query to finish");
            return;
        }

        if (this.getParamType() === "select") {
            if (Object.keys(this.getSelectOptions()).length === 0) {
                this.alertError("Need at least 1 select option");
                return;
            }
        }

        const { name, key } = this.state.paramConfigCurrent;
        const otherHaveSameKey =
            _.find(this.props.allParams, (p: ParamConfig) => p.key === key) !== undefined;
        const otherHaveSameName =
            _.find(this.props.allParams, (p: ParamConfig) => p.name === name) !== undefined;

        if (!otherHaveSameKey && otherHaveSameName) {
            // New param, not editing
            this.alertError(`Non-unique param name (${name}) - please rename`);
            return;
        }

        if (name.includes("{") || name.includes("}")) {
            this.alertError("Name should not contain { and } symbols");
            return;
        }

        this.save();
    };

    getForm = () => {
        return this.formRef?.current;
    };

    getParamType = (): ParamConfigType => {
        return this.state.paramConfigCurrent.type;
    };

    render = () => {
        return (
            <Modal
                className="param-modal"
                title={
                    <Typography.Paragraph
                        editable={{
                            onChange: (name) => {
                                this.onRename(name);
                            },
                        }}
                    >
                        {this.state.paramConfigCurrent.name || "Untitled param"}
                    </Typography.Paragraph>
                }
                visible
                onOk={this.clickSubmit}
                onCancel={this.handleCancel}
            >
                <Form
                    ref={this.formRef}
                    initialValues={{ type: this.state.paramConfigCurrent.type }}
                >
                    <Form.Item name="type" label="Type" rules={[{ required: true }]}>
                        <Select onChange={(e) => this.setParamConfig("type", e)}>
                            {Object.entries(this.modes).map(([name, value]) => {
                                return (
                                    <Select.Option key={value} value={value}>
                                        {name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Form>

                {this.renderBody()}
            </Modal>
        );
    };

    onRename = (name: string) => {
        this.setParamConfig("name", name);
    };

    renderBody = () => {
        switch (this.getParamType()) {
            case "select":
                return this.renderSelect();
            case "sql-select":
                return this.renderSqlSelect();
            case "date":
                return this.renderDate();
            case "date-period":
                return this.renderDatePeriod();
            case "number":
                return this.renderNumber();
            case "custom":
                return this.renderCustomString();

            default:
                return <div />;
        }
    };

    checkDefault = () => {
        const d = this.state.paramConfigCurrent.default;

        if (this.getParamType() === "select" || this.getParamType() === "sql-select") {
            const selectOptions = this.getSelectOptions();
            const selectValues = Object.values(selectOptions);
            if (selectValues.length > 0) {
                if (!selectValues.includes(d)) {
                    this.changeDefault(selectValues[0]);
                }
            }
        }
    };

    getDefaultValue = () => {
        this.checkDefault();
        return this.state.paramConfigCurrent.default;
    };

    changeDefault = (value: any) => {
        this.setParamConfig("default", value);
    };

    getDefaultDateConfig = (): DateParamConfig => {
        return {
            default: {
                type: "relative",
                specificValue: moment().format(dateFormat),
                plus: true,
                difference: 0,
                differenceType: "days",
            },
        };
    };

    renderDateConfig = (
        dateConfig: DateParamValueConfig,
        onChange: (name: string, value: any) => void,
    ): React.ReactNode => {
        const specificDate: Moment = dateConfig.specificValue
            ? moment(dateConfig.specificValue, dateFormat)
            : moment();

        const specificBlock = (
            <>
                <Col span={16}>
                    <label>Select specific date</label>
                    <DatePicker
                        style={{ width: "100%" }}
                        onChange={(v: Moment | null) => {
                            if (v !== null) onChange("specificValue", v.format(dateFormat));
                        }}
                        // @ts-ignore
                        value={specificDate}
                        format={dateFormat}
                    />
                </Col>
            </>
        );

        const relativeBlock = (
            <Col span={16}>
                <label>Select date relative to now</label>
                <Input.Group compact>
                    <Input type="text" value="NOW()" disabled style={{ width: "70px" }} />
                    <Select
                        value={dateConfig.plus ? "true" : "false"}
                        onChange={(value) => {
                            onChange("plus", value === "true");
                        }}
                    >
                        <Select.Option value="true">+</Select.Option>
                        <Select.Option value="false">-</Select.Option>
                    </Select>
                    <InputNumber
                        min={0}
                        value={dateConfig.difference ?? 0}
                        onChange={(value) => {
                            onChange("difference", value ?? 0);
                        }}
                    />
                    <Select
                        value={dateConfig.differenceType}
                        onChange={(value) => {
                            onChange("differenceType", value);
                        }}
                        style={{ width: "110px" }}
                    >
                        <Select.Option value="days">days</Select.Option>
                        <Select.Option value="weeks">weeks</Select.Option>
                        <Select.Option value="months">months</Select.Option>
                        <Select.Option value="years">years</Select.Option>
                    </Select>
                </Input.Group>
            </Col>
        );

        return (
            <div>
                <Row gutter={16}>
                    <Col span={8}>
                        <label>Date type</label>
                        <Select
                            style={{ width: "100%" }}
                            value={dateConfig.type}
                            onChange={(value) => {
                                onChange("type", value);
                            }}
                        >
                            <Select.Option value="specific">Specific</Select.Option>
                            <Select.Option value="relative">Relative</Select.Option>
                        </Select>
                    </Col>
                    {dateConfig.type === "specific" ? specificBlock : relativeBlock}
                </Row>
            </div>
        );
    };

    renderDatePeriod = () => {
        const dateConfig1: DateParamConfig =
            this.state.paramConfigCurrent?.dateConfig ?? this.getDefaultDateConfig();
        const dateConfig2: DateParamConfig =
            this.state.paramConfigCurrent?.dateConfig2 ?? this.getDefaultDateConfig();

        const block1 = this.renderDateConfig(dateConfig1.default, (name, value) => {
            // @ts-ignore
            dateConfig1.default[name] = value;
            this.setStateValue("paramConfigCurrent.dateConfig", dateConfig1);
        });

        const block2 = this.renderDateConfig(dateConfig2.default, (name, value) => {
            // @ts-ignore
            dateConfig2.default[name] = value;
            this.setStateValue("paramConfigCurrent.dateConfig", dateConfig2);
        });

        return (
            <div className="date-period-subcontent">
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Start date" key="1">
                        <Divider orientation="left">Default date</Divider>
                        {block1}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="End date" key="2">
                        <Divider orientation="left">Default date</Divider>
                        {block2}
                    </Tabs.TabPane>
                </Tabs>
            </div>
        );
    };

    renderDate = () => {
        const dateConfig: DateParamConfig =
            this.state.paramConfigCurrent?.dateConfig ?? this.getDefaultDateConfig();

        const block = this.renderDateConfig(dateConfig.default, (name, value) => {
            // @ts-ignore
            dateConfig.default[name] = value;
            this.setStateValue("paramConfigCurrent.dateConfig", dateConfig);
        });

        return (
            <div className="date-subcontent">
                <Divider orientation="left">Default date</Divider>
                {block}
            </div>
        );
    };

    renderCustomString = () => {
        return (
            <div className="string-subcontent">
                <Divider orientation="left">Default value</Divider>
                <Input
                    type="text"
                    value={this.state.paramConfigCurrent?.default ?? ""}
                    onChange={(e) => {
                        this.setParamConfig("default", e.target.value);
                    }}
                />
            </div>
        );
    };

    renderNumber = () => {
        return (
            <div className="string-subcontent">
                <Divider orientation="left">
                    <Checkbox
                        value={this.state.paramConfigCurrent?.haveMinimum}
                        onChange={(e) => {
                            this.setParamConfig("haveMinimum", e.target.checked);
                        }}
                    />{" "}
                    Minimum
                </Divider>
                {this.state.paramConfigCurrent?.haveMinimum === true && (
                    <InputNumber
                        value={this.state.paramConfigCurrent?.minimum ?? 0}
                        onChange={(v) => {
                            this.setParamConfig("minimum", v);
                        }}
                    />
                )}
                <Divider orientation="left">
                    <Checkbox
                        value={this.state.paramConfigCurrent?.haveMaximum}
                        onChange={(e) => {
                            this.setParamConfig("haveMaximum", e.target.checked);
                        }}
                    />{" "}
                    Maximum
                </Divider>
                {this.state.paramConfigCurrent?.haveMaximum === true && (
                    <InputNumber
                        value={this.state.paramConfigCurrent?.maximum ?? 0}
                        onChange={(v) => {
                            this.setParamConfig("maximum", v);
                        }}
                    />
                )}
                <Divider orientation="left">Default value</Divider>
                <InputNumber
                    value={this.state.paramConfigCurrent?.default ?? 0}
                    onChange={(v) => {
                        this.setParamConfig("default", v);
                    }}
                />
            </div>
        );
    };

    renderSqlSelect = () => {
        const columns = [
            {
                title: "Title",
                dataIndex: "title",
                key: "title",
                width: "40%",
                render: (title: string, record: any) => {
                    if (record.value === this.getDefaultValue())
                        return (
                            <div>
                                <b>{title}</b> (Default)
                            </div>
                        );

                    return (
                        <span>
                            {title} (
                            <a
                                style={{ whiteSpace: "nowrap" }}
                                onClick={() => this.changeDefault(record.value)}
                            >
                                Make default
                            </a>
                            )
                        </span>
                    );
                },
            },
            {
                title: "Value",
                dataIndex: "value",
                key: "value",
                width: "40%",
                render: (value: string, record: any) => {
                    if (record.title === this.getDefaultValue())
                        return (
                            <span>
                                <b>{value}</b>
                            </span>
                        );

                    return <span>{value}</span>;
                },
            },
        ];

        const dataSource = Object.entries(this.getSelectOptions()).reduce(
            (accumulator: any, [title, value]) => {
                accumulator.push({
                    title,
                    value,
                });
                return accumulator;
            },
            [],
        );

        return (
            <div className="sql-subcontent">
                <div className="sql-title">
                    <label>SQL to generate options:</label>
                    <Tooltip
                        title={
                            <span>
                                SQL code should return one or two columns. First one will be used as
                                an option title, second will be used as an option value. If one
                                column is returned - option title will be the same as value.
                                Example: <code>SELECT name, code FROM employees</code>
                            </span>
                        }
                    >
                        <QuestionCircleFilled />
                    </Tooltip>
                </div>
                <DbContext.Consumer>
                    {(dbContext) => (
                        <SqlEditor
                            schema={dbContext.dbSchema}
                            height={150}
                            value={this.state.paramConfigCurrent.optionsSql || ""}
                            onChange={(value) => {
                                this.setParamConfig("optionsSql", value);
                            }}
                        />
                    )}
                </DbContext.Consumer>
                <Button
                    loading={this.state.sqlLoading}
                    size="small"
                    onClick={this.previewSqlResults}
                >
                    Preview result
                </Button>
                {this.state.sqlResultPreviewed && (
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        bordered
                        pagination={false}
                        size="small"
                    />
                )}
            </div>
        );
    };

    previewSqlResults = async () => {
        this.setState({ sqlLoading: true });
        try {
            const previewResult = await this.props.onSqlPreview(this.state.paramConfigCurrent);
            this.setParamConfig("options", previewResult.result);
            this.setState({ sqlResultPreviewed: true });
        } catch (e) {
            this.alertError(`Error with SQL preview:${e.toString()}`);
        }

        this.setState({ sqlLoading: false });
    };

    renderSelect = () => {
        const columns = [
            {
                title: "Title",
                dataIndex: "title",
                key: "title",
                width: "40%",
                render: (title: string, record: any) => {
                    if (record.value === this.getDefaultValue())
                        return (
                            <div>
                                <b>{title}</b> (Default)
                            </div>
                        );

                    return (
                        <span>
                            {title} (
                            <a
                                style={{ whiteSpace: "nowrap" }}
                                onClick={() => this.changeDefault(record.value)}
                            >
                                Make default
                            </a>
                            )
                        </span>
                    );
                },
            },
            {
                title: "Value",
                dataIndex: "value",
                key: "value",
                width: "40%",
                render: (value: string, record: any) => {
                    if (record.title === this.getDefaultValue())
                        return (
                            <span>
                                <b>{value}</b>
                            </span>
                        );

                    return <span>{value}</span>;
                },
            },
            {
                title: "",
                dataIndex: "title",
                key: "title",
                render: (title: string) => {
                    return (
                        <div className="action-td">
                            <a onClick={() => this.deleteSelectOption(title)}>Remove</a>
                        </div>
                    );
                },
            },
        ];

        const dataSource = Object.entries(this.getSelectOptions()).reduce(
            (accumulator: any, [title, value]) => {
                accumulator.push({
                    title,
                    value,
                });
                return accumulator;
            },
            [],
        );

        return (
            <div className="select-subcontent">
                <div className="adders">
                    <div className="select-adder">
                        <Divider orientation="left">Select options</Divider>
                        <Form
                            ref={this.optionsFormRef}
                            name="basic"
                            layout="vertical"
                            onFinish={this.addSelectOption}
                        >
                            <Row gutter={8}>
                                <Col flex={8}>
                                    <Form.Item
                                        label="Title"
                                        name="title"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input option title!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                if (
                                                    this.state.optionTitle ===
                                                    this.state.optionValue
                                                ) {
                                                    if (this.optionsFormRef?.current)
                                                        this.optionsFormRef.current.setFieldsValue({
                                                            value: newValue,
                                                        });
                                                    this.setState({
                                                        optionTitle: newValue,
                                                        optionValue: newValue,
                                                    });
                                                } else
                                                    this.setState({
                                                        optionTitle: newValue,
                                                    });
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col flex={8}>
                                    <Form.Item label="Value" name="value">
                                        <Input
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                this.setState({
                                                    optionValue: newValue,
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col flex={2}>
                                    <Form.Item label=" ">
                                        <Button htmlType="submit" className="fluid">
                                            Add
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    bordered
                    pagination={false}
                    size="small"
                />
                <div className="select-footer">
                    <Popconfirm
                        title="Are you sure to clear all options?"
                        onConfirm={() => {
                            this.setParamConfig("options", []);
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button size="small">Clear options</Button>
                    </Popconfirm>
                </div>
            </div>
        );
    };

    getSelectOptions = (): { [name: string]: string } => {
        let options = this.state.paramConfigCurrent?.options ?? {};
        if (options instanceof Array) options = {};

        return options;
    };

    addSelectOption = (values: any) => {
        const items = this.getSelectOptions();
        items[values.title] = values.value;

        this.setParamConfig("options", items);
        if (this.optionsFormRef?.current)
            this.optionsFormRef.current.setFieldsValue({
                title: "",
                value: "",
            });
        this.setState({
            optionTitle: "",
            optionValue: "",
        });

        this.checkDefault();
    };

    deleteSelectOption = (title: string) => {
        const items = this.getSelectOptions();
        delete items[title];
        this.setParamConfig("options", items);

        this.checkDefault();
    };
}
